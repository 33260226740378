import React from 'react'
import Container from '../componants/Container'
import CarrierMasterContent from './carrierMasterDetails/carrierMasterContent'

const CarrierMaster = () => {
  return (
    <Container>
    <CarrierMasterContent />
  </Container>
  )
}

export default CarrierMaster