const initialState = {
    MemberCode: localStorage.getItem('MemberCode') ? localStorage.getItem('MemberCode') : localStorage.getItem('CCOD'),
    LoginKey: localStorage.getItem('Lkey')
};


export default function  (state = initialState, action){
    switch (action.type)
    {
        case 'CHANGE_MEMBERCODE':
            return {...state, MemberCode: action.payload}
        case 'CHANGE_LOGINKEY':
            return {...state, LoginKey: action.payload}
        default  :
            return state
    }
    
}    

