import React from 'react'
import Container from '../componants/Container'
import UserprofileContent from './userProfile/UserprofileContent'

const UserProfile = () => {
    return (
        <Container >
            <UserprofileContent />
        </Container>
    )
}

export default UserProfile