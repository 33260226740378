import React from "react";
import Container from "../componants/Container";
import MemberDetailsContent from "./memberDetails/MemberDetailsContent";

const MemberDetails = () => {
  return (
    <Container>
      <MemberDetailsContent />
    </Container>
  );
};

export default MemberDetails;
