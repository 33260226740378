import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";



const useStyles = makeStyles({
    root: {
      maxWidth: 645,
      backgroundImage:
        'linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))',
    },
    paperContainer: {
      height: 356,
      minWidth: 900,
      backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../assets/img/Back_Projects_part1.png")})`,
      backgroundPosition: "0,0",
      backgroundColor: '#174C38',
      marginBottom: 20,
      display:'flex',
      direction: 'row',
      alignItems: 'center'
    },
    
    textSecondary: {
      color: "#ffffff",
    },
  });
  
const ComingSoon = () => {

    const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.paperContainer}>
        <CardActionArea>
          <CardContent className={classes.textSecondary}>
            <Typography gutterBottom variant="h5" component="h2" align="center">
              This page is under construction!
            </Typography>
            
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ComingSoon;
