import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const DeleteCellRenderer = (props) => {
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const [open, setOpen] = useState(false);
  const [warnopen, setWarnOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseOkay = () => {
    setOpen(false);
    setWarnOpen(false);
  };

  const deleteCarrier = () => {
    // http://api.greenabl.us/api/GreenCarrier?MemberCode=1003&SCAC=32&Valid=1
    axios
      .post(
        `${APIBASE_URL}/GreenCarrier?MemberCode=${MemberCode}&SCAC=${props.value}&Delete=1`
      )
      .then((resp) => {
        console.log(resp);
        if (resp.data === 1) {
          setOpen(false);
          props.update();
        }
        if (resp.data === 2) {
          setWarnOpen(true);
        }
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <DeleteIcon></DeleteIcon>
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Please confirm Delete action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this Carrier? This action can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteCarrier} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={warnopen}
        onClose={handleCloseOkay}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title-1">
          {"Cannot Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          This carrier has existing shipments. Deletion is not allowed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseOkay} color="primary">
            Okay
          </Button>
         
        </DialogActions>
      </Dialog>
      {/* <Snackbar  open={warnopen}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
          container={document.body} // this ensures it's portaled to body
        >
          <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
            This carrier has existing shipments. Deletion is not allowed.
          </Alert>
      </Snackbar> */}
    </div>
  );
};

export default DeleteCellRenderer;
