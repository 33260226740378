import React from 'react';
import ReactApexChart from 'react-apexcharts';



// const useStyles = makeStyles((theme) => ({
//     root: {
//         maxWidth: 645,
//         backgroundImage:
//             "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
//     },

//     paperContainer: {
//         height: 300,
//         minWidth: 300,
//         backgroundPosition: "0,0",
//         backgroundColor: "#FFFFFF",
//         marginBottom: 20,

//     },


// }));


const WidgetPieChart = ({ data }) => {
    console.log(data);
    if (data.series && data.options) {
        return (
            <ReactApexChart
                options={data?.options}
                series={data?.series}
                type={data?.options.chart.type}
                width="100%"
                height="300"
            />
        )
    } else {
        return null;
    }
}

export default WidgetPieChart