import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ProjectCategories } from "./ProjectCategories";
import ProjectDetails from "./ProjectDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  table: {
    minWidth: 500,
    height: 300,
    overflowY: "auto",
  },
  paperContainer1: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    padding: "35px",
    marginBottom: 20,
    "@media screen and (max-width: 576px)": {
      padding: 10,
    },
  },
  GridContainer: {
    minHeight: 356,
    minWidth: 900,
    backgroundPosition: "0,0",
    marginBottom: 20,
  },
  textSecondary: {
    color: "#ffffff",
  },
  smallWidth: {
    width: 400,
  },
  largeWidth: {
    width: 600,
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const OffsetPreference = ({ onStepChange }) => {
  const classes = useStyles();
  const mounted = useRef(true);
  const APIBASE_URL = useMemo(() => process.env.REACT_APP_APIBASE_URL, []);
  // const MemberCode = localStorage.getItem("MemberCode");
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const [isExcluded, setisExcluded] = useState(false);
  const [projectList, setProjectList] = useState([]);
  // const [projectTypeList, setProjectTypeList] = useState([]);
  const [projectTypeListModalOpen, setProjectTypeListModalOpen] =
    useState(false);
  const [selectedId, setSelectedID] = useState("");
  const [open, setOpen] = React.useState(false);
  const [projectIDForDetails, setProjectIDForDetails] = useState();

  useEffect(() => {
    mounted.current = true;

    axios
      .get(
        // `http://api.greenabl.us/api/GreenMemberProject?MemberCode=1005`
        `${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);
        if (mounted.current) {
          const pIds = res.data.map((el) => el.PId);
          console.log(pIds);
          const projDetails = res.data;

          setProjectList(projDetails);
        }
      })
      .catch((err) => {
        console.log(err);
        // setProjectList();
      });

    return () => {
      mounted.current = false;
    };
  }, [APIBASE_URL, MemberCode, isExcluded]);

  // useEffect(() => {
  //   mounted.current = true;

  //   axios
  //     .get(
  //       // `http://api.greenabl.us/api/GreenMemberProject`
  //       `${APIBASE_URL}/GreenMemberProject`
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       if (mounted.current) {
  //         const projTypeList = res.data;
  //         setProjectTypeList(projTypeList);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // setProjectList();
  //     });

  //   return () => {
  //     mounted.current = false;
  //   };
  // }, [APIBASE_URL, MemberCode]);

  const handleProjectTypeListModalClose = () => {
    setProjectTypeListModalOpen(false);
  };
  // const handleViewProjectDetails = (id, cnt) => {

  //   const step3Data = "";
  //   setProjectTypeListModalOpen(false);
  //   onStepChange("step2", step2data, step3Data);
  // };

  const handleProjectTypeListModalOpen = () => {
    setProjectTypeListModalOpen(true);
  };

  const handleChange = (event) => {
    // if (event.target.checked) {
    //   setSelectedID([...selectedId, pid]);
    // } else {
    //   setSelectedID((prev) => {
    //     return prev.filter((el) => el !== pid);
    //   });
    // }
    console.log(event.target.value);
    setSelectedID(parseInt(event.target.value));
  };

  const excludeProject = () => {
    console.log(selectedId);
    const pid = selectedId;
    axios
      .post(
        //http://api.greenabl.us/api/GreenMemberProject?MemberCode=1005&ProjectId=2&ActionType=1
        `${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}&ProjectId=${pid}&ActionType=1`
      )
      .then((res) => {
        console.log(res.data);
        setisExcluded(true);
        // const projTypeList = res.data;
        // setProjectTypeList(projTypeList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isExcluded) {
      setTimeout(() => {
        if (mounted.current) {
          setisExcluded(false);
        }
      }, 5000);
    }
  }, [isExcluded]);

  const handleClose = () => {
    setOpen(false);
  };

  const openDetails = (pid) => {
    setOpen(true);
    setProjectIDForDetails(pid);
  };

// eslint-disable-next-line no-unused-vars
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAJv5cjWWDCJa_4Y39L9RYufUltheQgh8o",
  });

  return (
    <>
    {isLoaded &&("")}
      {isExcluded && (
        <div className={classes.root}>
          <Alert severity="success" color="info">
            Selected project is excluded successfuly!
          </Alert>
        </div>
      )}
      <Card className={classes.paperContainer1}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 10,
            marginBottom: 15,
            borderRadius: 4,
          }}
        >
          <Typography variant="h5" component="h2" align="center">
            Carbon Credit Portfolio
          </Typography>
        </div>
        <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 4 }}>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">
              Selected Projects for investments against carbon offset (max 4){" "}
            </FormLabel>

            <RadioGroup
              aria-label="offsetList"
              name="offsetList"
              value={selectedId}
              onChange={handleChange}
            >
              {projectList.map((proj) => (
                <React.Fragment key={proj.PId}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <FormControlLabel
                      value={proj.PId}
                      control={<Radio />}
                      label={proj.PName}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        openDetails(proj.PId);
                      }}
                      style={{
                        textTransform: "capitalize",
                        height: "fit-content",
                      }}
                    >
                      Details...
                    </Button>
                  </div>
                </React.Fragment>
              ))}
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{ width: 220, textTransform: "capitalize" }}
              onClick={handleProjectTypeListModalOpen}
              disabled={projectList.length > 3}
            >
              Include Project&#40;s&#41;
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{ width: 220, textTransform: "capitalize" }}
              disabled={selectedId === ""}
              onClick={excludeProject}
            >
              Exclude Project
            </Button>
          </div>
        </div>
      </Card>

      {projectTypeListModalOpen && (
        <>
          <ProjectCategories
            handleClose={handleProjectTypeListModalClose}
            projectTypeListModalOpen={projectTypeListModalOpen}
            onStepChange={onStepChange}
          />
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ProjectDetails pid={projectIDForDetails} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default OffsetPreference;
