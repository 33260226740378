import { Card, CardContent, Grid, Typography } from "@material-ui/core";
// import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import React from "react";

const ResourcesContent = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/a_guide_to_carbon_neutral_shipping_delivery_for_supply_chain_managers.png")}
            />

            <CardContent>
              <Typography variant="h6">
                A Guide to Carbon Neutral Shipping & Delivery for Supply Chain
                Managers
              </Typography>
              <Typography paragraph variant="body2">
                According to a recent report by Accenture, 34% of the largest
                companies around the world have committed to taking action
                toward net zero emissions. The
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/a-guide-to-carbon-neutral-shipping-delivery-for-supply-chain-managers/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/carbon_offset_shipping.png")}
            />

            <CardContent>
              <Typography variant="h6">
                Supply Chain & Shipping Carbon Credit: How Supply Chains
                Offset Emissions for a Greener Future
              </Typography>
              <Typography paragraph variant="body2">
                A recent report by the Rhodium Group stated that greenhouse gas
                emissions in the United States increased by 1.3% in 2022.
                Transportation and industry, two{" "}
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/supply-chain-shipping-carbon-offsetting-how-supply-chains-offset-emissions-for-a-greener-future/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/George-Goldman-Joins-Greenabl-300x200.png")}
            />

            <CardContent>
              <Typography variant="h6">
                Industry veteran George Goldman takes the helm at Greenabl
              </Typography>
              <Typography paragraph variant="body2">
                Goldman brings almost 40 years of experience in global
                transportation to the new shippers association February 27, 2023
                New York, NY Greenabl Shippers{" "}
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/george-goldman-to-lead-greenabl/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/Gemini-Shippers-Greenabl-partnership-net-zero-decarbonization-300x200.png")}
            />

            <CardContent>
              <Typography variant="h6">
                Gemini Shippers unlocks path to net zero with Greenabl
                partnership
              </Typography>
              <Typography paragraph variant="body2">
                A strategic partnership between associations will enable member
                companies to connect their supply chain operations and
                decarbonization efforts February 14, 2023 – New York, NY{" "}
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/gemini-shippers-greenabl-partnership-path-to-net-zero/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/supply-chain-decarbonization-logistics-decarbonization-greenabl-300x169.png")}
            />

            <CardContent>
              <Typography variant="h6">
                Greenabl launches to accelerate supply chain decarbonization
              </Typography>
              <Typography paragraph variant="body2">
                Introducing a sustainable shippers association to collectively
                address GHG emissions within supply chains February 1, 2023 –
                New York, NY – Greenabl Shippers Association, the{" "}
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/greenabl-launches-to-accelerate-supply-chain-decarbonization/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardMedia
              component="img"
              height="194"
              src={require("../../assets/greenabl_resources_images/reduce-scope-3-emissions-transportation-ocean-freight-greenabl-300x200.png")}
            />

            <CardContent>
              <Typography paragraph variant="h6">
                Lack of data leaves shippers reliant on 3PLs to reduce Scope 3
                emissions
              </Typography>
              <Typography paragraph variant="body2">
                Shippers generally don’t have the time and expertise to
                determine which emissions calculators provide the most accurate
                data. Shippers looking to measure and mitigate emissions
              </Typography>

              <Link
                color="primary"
                href="https://greenabl.co/lack-of-data-leaves-shippers-reliant-on-3pls-to-reduce-scope-3-emissions-joc/"
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "50%" }}
              >
                continue reading
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ResourcesContent;
