import { Button, Grid, IconButton, useMediaQuery } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Alert from "@material-ui/lab/Alert";
import { useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ProjectCategories } from "./ProjectCategories";
import ProjectDetails from "./ProjectDetails";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },
  rootA: {
    maxWidth: 645,
  },
  table: {
    minWidth: 1050,
    height: 700,
    overflowY: "auto",
  },
  textSecondary: {
    color: "#ffffff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  media: {
    height: 200,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  smallWidth: {
    width: 400,
  },
  largeWidth: {
    width: 1100,
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  greenBG: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: 8,
  },
  shadow: {
    boxShadow: "3px 2px 14px 1px rgba(20,19,19,0.75)",
    // -webkit-box-shadow: 3px 2px 14px 1px rgba(20,19,19,0.75);
    // -moz-box-shadow: 3px 2px 14px 1px rgba(20,19,19,0.75);
  },
}));
const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
export const ProjectLists = ({
  onStepChange,
  dataFromStepOne,
  goback,
  currentStep,
}) => {
  const mounted = useRef(true);
  console.log(dataFromStepOne);
  const isHamburgerShown = useMediaQuery("(max-width:768px)");
  const ProjectId = dataFromStepOne.id;
  const ProjectCntry = dataFromStepOne.country;
  // const MemberCode = localStorage.getItem("MemberCode");
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);

  const classes = useStyles();
  const [projectListDetails, setprojectListDetails] = useState("");
  const [projectIDForDetails, setProjectIDForDetails] = useState();
  // const [projectDetails, setprojectDetails] = useState([]);
  // const [projectImageDetails, setprojectImageDetails] = useState([]);
  // const [center, setcenter] = useState({ lat: 46.658773, lng: -98.339812 });
  // const [state, setState] = useState({});
  const [isIncluded, setisIncluded] = useState(false);
  const [folioIds, setFolioIds] = useState([]);
  const [selectedIds, setSelectedIDs] = useState([]);
  const [projectTypeListModalOpen, setProjectTypeListModalOpen] =
    useState(false);

  useEffect(() => {
    console.log("hi");
    (async () => {
      try {
        const projectListRes = await axios.get(
          `${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}`
        );
        const countryProjectRes = await axios.get(
          // `http://api.greenabl.us/api/GreenMemberProject?ProjectType=Wooden Building Elements`
          `${APIBASE_URL}/GreenMemberProject?ProjectType=${ProjectId}&Country=${ProjectCntry}`
        );
        const FIds = projectListRes.data.map((el) => el.PId);
        setprojectListDetails(
          countryProjectRes.data.map((dta) => {
            console.log(dta);
            console.log(FIds);
            if (FIds.some((fid) => fid === dta.PId)) {
              dta.choosen = true;
              console.log(dta);
              return dta;
            }
            dta.choosen = false;
            return dta;
          })
        );
        setFolioIds(projectListRes.data);
      } catch (error) {
        console.log(error)
      }
    })();
    // axios
    //   .get(`${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}`)
    //   .then((res) => {
    //     const FIds = res.data.map((el) => el.PId);
    //     // console.log(FIds);
    //     setFolioIds(res.data);
    //     axios
    //       .get(
    //         // `http://api.greenabl.us/api/GreenMemberProject?ProjectType=Wooden Building Elements`
    //         `${APIBASE_URL}/GreenMemberProject?ProjectType=${ProjectId}&Country=${ProjectCntry}`
    //       )
    //       .then((res) => {
    //         console.log(res.data);

    //         setprojectListDetails(
    //           res.data.map((dta) => {
    //             console.log(dta);
    //             console.log(FIds);
    //             if (FIds.some((fid) => fid === dta.PId)) {
    //               dta.choosen = true;
    //               console.log(dta);
    //               return dta;
    //             }
    //             dta.choosen = false;
    //             return dta;
    //           })
    //         );
    //         console.log(projectListDetails);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [ProjectId, MemberCode, ProjectCntry]);

  // eslint-disable-next-line no-unused-vars
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAJv5cjWWDCJa_4Y39L9RYufUltheQgh8o",
  });

  const viewProjectDetails = (pid) => {
    // const ProjId = pid;
    setProjectIDForDetails(pid);
    handleOpen();
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    // console.log(projectDetails);
    // var latx = projectDetails[0].locX;
    // var laty = projectDetails[0].locY;
    // const center_1 = { lat: latx, lng: laty };
    // // const center_1 = { lat: 46.658773, lng: -98.339812 };
    // setcenter(center_1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeChkbx = (event, pid) => {
    if (event.target.checked) {
      setSelectedIDs([...selectedIds, pid]);
    } else {
      setSelectedIDs((prev) => {
        return prev.filter((el) => el !== pid);
      });
    }
  };
  const handleProjectTypeListModalOpen = () => {
    setSelectedIDs([]);
    setProjectTypeListModalOpen(true);
    handleCloseMenu();
  };
  const handleProjectTypeListModalClose = () => {
    setProjectTypeListModalOpen(false);
  };

  function handleStepChange(a, b, c) {
    setProjectTypeListModalOpen(false);
    onStepChange(a, b, c);
  }

  const includeProject = () => {
    handleCloseMenu();
    console.log(selectedIds);

    if (selectedIds.length + folioIds.length > 4) {
      alert("more than max allowed project selected, remove one!");
      return;
    }
    //http://api.greenabl.us/api/GreenMemberProject?MemberCode=1005&ProjectId=2&ActionType=0
    //const pid = selectedIds[0];
    const projectIds = selectedIds.join(', ');
    console.log (projectIds);
    axios
      .post(
        //http://api.greenabl.us/api/GreenMemberProject?MemberCode=1005&ProjectId=2&ActionType=0
        `${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}&ProjectId=${projectIds}&ActionType=0`
      )
      .then((res) => {
        console.log(res.data);
        setisIncluded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isIncluded) {
      setTimeout(() => {
        if (mounted.current) {
          setisIncluded(false);
          goback();
        }
      }, 3000);
    }
  }, [goback, isIncluded]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 70,
          right: 20,
        }}
      >
        {isHamburgerShown ? (
          <>
            <IconButton onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="resp-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={goback}>Back</MenuItem>
              <MenuItem onClick={includeProject} disabled={!selectedIds.length}>
                Save
              </MenuItem>
              <MenuItem onClick={handleProjectTypeListModalOpen}>
                Browse Projects
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button
              disabled={currentStep === "step1"}
              onClick={goback}
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
            >
              Back
            </Button>
            <Button
              disabled={!selectedIds.length}
              onClick={includeProject}
              color="primary"
              variant="contained"
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
            <Button
              onClick={handleProjectTypeListModalOpen}
              color="primary"
              variant="contained"
            >
              Browse Projects
            </Button>
          </>
        )}
      </div>
      {isIncluded && (
        <div className={classes.root}>
          <Alert severity="success" color="info">
            Selected project is included successfuly!
          </Alert>
        </div>
      )}
      <Grid
        container
        spacing={2}
        // style={{ paddingLeft: 25, paddingRight: 25 }}
      >
        {projectListDetails &&
          projectListDetails.map((projDtls, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
              <Card className={classes.rootA}>
                <CardHeader
                  style={{ minHeight: 100 }}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {projDtls.PName[0]}
                    </Avatar>
                  }
                  // action={
                  //   <IconButton aria-label="settings">
                  //     <MoreVertIcon />
                  //   </IconButton>
                  // }
                  title={projDtls.PName}
                  subheader={projDtls.ProjectLocation.split(/[,]+/).pop()}
                />
                <CardMedia className={classes.media} image={projDtls.imgUrl} />
                <CardContent>
                  <Typography paragraph>
                    Project Location: <br />
                    {projDtls.ProjectLocation}
                  </Typography>
                  <Typography paragraph>
                    Available CO<sub>2</sub>e:{" "}
                    {projDtls?.PAvailable_carbon_in_kg / 1000} MTon
                    <br />
                    Carbon Credit Cost per MTon: $
                    {projDtls?.Pcost_per_kg_carbon_in_usd_cents * 10} <br />
                  </Typography>

                  <FormGroup
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "space-between",
                    }}
                  >
                    {!projDtls.choosen ? (
                      <FormControlLabel
                        key={projDtls.PId}
                        control={
                          <Checkbox
                            // checked={gilad}
                            onChange={(e) => handleChangeChkbx(e, projDtls.PId)}
                            name={projDtls.PId}
                          />
                        }
                        label="Select Project"
                        maxWidth="50%"
                      />
                    ) : (
                      "Already Selected"
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        viewProjectDetails(projDtls.PId);
                      }}
                      style={{ marginLeft: "auto" }}
                    >
                      View Project
                    </Button>
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div>
          <ProjectDetails pid={projectIDForDetails} handleClose={handleClose} />
        </div>
      </Modal>

      {projectTypeListModalOpen && (
        <ProjectCategories
          handleClose={handleProjectTypeListModalClose}
          projectTypeListModalOpen={projectTypeListModalOpen}
          onStepChange={handleStepChange}
        />
      )}
    </>
  );
};
