import { ThemeProvider } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Provider } from "react-redux";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MemberDetails from "./pages/MemberDetails";
import Payments from "./pages/Payments";
import Resources from "./pages/Resources";
import Shipment from "./pages/Shipment";
import UserProfile from "./pages/UserProfile";
import store from "./store";
import theme from "./theme";
import CarrierMaster from "./pages/carrierMaster";

const PrivateRoute = ({ children }) => {
  const isAuth = localStorage.getItem("LKey");
  return isAuth ? children : <Navigate to="/" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/Home",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/Dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/MemberDetails",
    element: (
      <PrivateRoute>
        <MemberDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/Shipment/CNN",
    element: (
      <PrivateRoute>
        <Shipment sub="CNN" />
      </PrivateRoute>
    ),
  },
  {
    path: "/Shipment/CN",
    element: (
      <PrivateRoute>
        <Shipment sub="CN" />
      </PrivateRoute>
    ),
  },
  {
    path: "/Shipment/LCLCNN",
    element: (
      <PrivateRoute>
        <Shipment sub="LCL-CNN" />
      </PrivateRoute>
    ),
  },
  {
    path: "/Shipment/LCLACNN",
    element: (
      <PrivateRoute>
        <Shipment sub="LCLA-CNN" />
      </PrivateRoute>
    ),
  },
  {
    path: "/Shipment/DOMCNN",
    element: (
      <PrivateRoute>
        <Shipment sub="DOM-CNN" />
      </PrivateRoute>
    ),
  },
  {
    path: "/Payments",
    element: (
      <PrivateRoute>
        <Payments />
      </PrivateRoute>
    ),
  },
  {
    path: "/Resources",
    element: (
      <PrivateRoute>
        <Resources />
      </PrivateRoute>
    ),
  },
  {
    path: "/carrierMaster",
    element: (
      <PrivateRoute>
        <CarrierMaster />
      </PrivateRoute>
    ),
  },
  {
    path: "/UserProfile",
    element: (
      <PrivateRoute>
        <UserProfile />
      </PrivateRoute>
    ),
  },
]);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
