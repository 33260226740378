import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import React from "react";
import CNNDomesticFCL from "./CNNDomesticFCL";
import CNNDomesticLCL from "./CNNDomesticLCL";

const CNNDomestic = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
          <TabList
            onChange={handleChange}
            aria-label="Domestic FCL LCL Shipments"
            style={{ backgroundColor: "#fff" }}
          >
            <Tab label="Domestic-FCL" value="1" />
            <Tab label="Domestic-LCL" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CNNDomesticFCL />
        </TabPanel>
        <TabPanel value="2">
          <CNNDomesticLCL />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CNNDomestic;
