import { Grid, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      'linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))',
  },
  table: {
    minWidth: 500,
    height: 300,
    overflowY: 'auto',
  },
  paperContainer1: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require('../../assets/img/topBack.jpg')})`,
    backgroundPosition: '0,0',
    backgroundColor: '#174C38',
    padding: '35px',
    marginBottom: 20,
    '@media screen and (max-width: 576px)': {
      padding: 10,
    },
  },
  GridContainer: {
    minHeight: 356,
    minWidth: 900,
    // backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.6), rgba(255,255,255,.6)), url(${require("../assets/img/bg1.jpg")})`,
    backgroundPosition: '0,0',
    marginBottom: 20,
  },
  textSecondary: {
    color: '#ffffff',
  },
  //   smallWidth: {
  //     width: 400,
  //   },
  //   largeWidth: {
  //     width: 600,
  //   },
  //   progressWidth: {
  //     width: 300,
  //   },
  //   modal: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  memeberGrid: {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: '1fr 1fr',
    '@media screen and (max-width: 576px)': {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default function CompanyDetails() {
  const classes = useStyles();
  const APIBASE_URL = useMemo(() => process.env.REACT_APP_APIBASE_URL, []);
  const MemberCode = useSelector(state => state.memberreducer.MemberCode)
  const [companyDetails, setCompanyDetails] = useState([]);
  const [totalTues, setTotaltues] = useState('');
  const [offsetTarget, setOffsetTarget] = useState('');

  useEffect(() => {
    console.log(MemberCode);
    axios
      .get(
        // `http://api.greenabl.us/api/GreenMember?MemberCode=1005`
        `${APIBASE_URL}/GreenMember?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);

        const comDetails = res.data;
        setCompanyDetails(comDetails);
        setTotaltues(comDetails[0].EstTEUcurYr);
        setOffsetTarget(comDetails[0].OffsetTarget);
      })
      .catch((err) => {
        console.log(err);
        // setCompanyDetails();
      });
  }, [APIBASE_URL, MemberCode]);

  return (
    <Card className={classes.paperContainer1}>
      <div
        style={{
          backgroundColor: '#fff',
          padding: 10,
          marginBottom: 15,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" component="h2" align="center">
          {companyDetails[0]?.Company}
        </Typography>
      </div>

      <div className={classes.memeberGrid}>
        <div style={{ padding: 15, backgroundColor: '#fff', borderRadius: 4 }}>
          <Typography variant="h6">Address</Typography>
          <Typography variant="body2">
            {companyDetails[0]?.AddressLine1},{' '}
            {companyDetails[0]?.AddressLine2
              ? companyDetails[0]?.AddressLine2
              : ''}
          </Typography>
          <Typography variant="body2">
            {companyDetails[0]?.City}, {companyDetails[0]?.State},{' '}
            {companyDetails[0]?.Zipcode}, {companyDetails[0]?.Country}
          </Typography>
          <Typography variant="body2" style={{ paddingTop: 50 }}>
            website: {companyDetails[0]?.Website}, <br />
            Phone: {companyDetails[0]?.PhoneNumber}, <br />
            email: {companyDetails[0]?.EMAIL}
            <br />
          </Typography>
        </div>
        <div>
          <div
            style={{
              padding: 15,
              backgroundColor: '#fff',
              borderRadius: 4,
              marginBottom: 7.5,
            }}
          >
            <Typography variant="body2">
              Membership Type: {companyDetails[0]?.MemberType}
            </Typography>
          </div>
          <div
            style={{
              padding: 15,
              backgroundColor: '#fff',
              borderRadius: 4,
            }}
          >
            <Typography variant="h6">Goal</Typography>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                Total TEUs/ Year <br />
                (estimated)
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="total-teus"
                  size="small"
                  variant="outlined"
                  value={totalTues}
                />
              </Grid>
              <Grid item sm={6}>
                Carbon Credit Target (%)
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="offset"
                  size="small"
                  variant="outlined"
                  value={offsetTarget}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Card>
  );
}
