import { Grid, IconButton, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { CancelOutlined, PictureAsPdf } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },

  widthL: {
    minWidth: 220,
  },
  widthS: {
    minWidth: 120,
  },

  paperContainer1: {
    height: 356,
    minWidth: 900,
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/Back_Projects_part1.png")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    marginBottom: 20,
  },

  GridContainer: {
    minHeight: 356,
    margin: "0 20px",
    "@media screen and (max-width:576px)": {
      margin: "0",
    },
  },
  textSecondary: {
    color: "#ffffff",
  },
  textData: {
    color: "#1164B4",
    fontSize: "14px",
    fontWeight: "bold",
  },
  paper: {
    position: "absolute",
    backgroundColor: "var(--neutralOffWhite)",
    border: "2px solid #000",
    boxShadow: "var(--neutralGray)",
    padding: "0px 25px 15px 30px",
  },
  smallWidth: {
    width: 400,
  },
  mediumWidth: {
    width: 750,
  },
  largeWidth: {
    width: "90%",
    maxWidth: 1200,

    // "@media screen and (max-width:768px)": {
    //     width: "unset",
    //     maxWidth: 700
    // },
    // "@media screen and (max-width:1024px)": {
    //     width: "unset",
    //     maxWidth: 900
    // },
  },

  height: {
    height: 600,
    overflow: "auto",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[9],
    padding: theme.spacing(2, 4, 3),
  },
  btn: {
    border: "1px solid #666",
    borderRadius: 0,
    padding: "15px 10px",
    marginBottom: "65px",
    textAlign: "center",
    cursor: "pointer",
  },

  smallBold: {
    fontSize: 12,
    fontWeight: "bold",
  },
}));
const CNcolumns = [
  // { field: "id", headerName: "ID", width: 0,  },

  {
    field: "InvoiceNumber",
    headerName: "Invoice Number",
    width: 120,
  },
  {
    field: "ProjectName",
    headerName: "Project Name",
    width: 180,
  },
  {
    field: "ProjectType",
    headerName: "Project Type",
    width: 180,
  },

  {
    field: "Origin",
    headerName: "Origin",
    width: 200,
  },
  {
    field: "Destination",
    headerName: "Destination",
    width: 250,
  },
  {
    field: "Carrier",
    headerName: "Carrier",
    width: 150,
  },
  {
    field: "ContainerNo",
    headerName: "Container No",
    width: 150,
  },
  {
    field: "ContainerSize",
    headerName: "Container Size",
    width: 150,
  },

  {
    field: "CarbonEmMton",
    headerName: "CO2Emn(MTon)",
    width: 180,
  },
  {
    field: "OffsetAmount",
    headerName: "Carbon Credit Amt",
    width: 180,
  },
  {
    field: "GreenablCharge",
    headerName: "Greenabl Charge",
    width: 180,
  },
  {
    field: "ProjectCostRate",
    headerName: "Project Cost Rate",
    width: 120,
  },
  {
    field: "OffsetCertificateTransactionId",
    headerName: "CC Certificate Transaction Id",
    width: 120,
  },
];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const CloseButton = ({ onClose }) => {
  return (
    <IconButton
      aria-label="close"
      color="primary"
      onClick={onClose}
      style={{ float: "right" }}
    >
      <CancelOutlined />
    </IconButton>
  );
};

const PaymentsContent = () => {
  // const MemberCode = localStorage.getItem("MemberCode");
  let gridApi = React.useRef(null);
  const [modalStyle] = React.useState(getModalStyle);
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const MemberName = localStorage.getItem("Name");
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  // const [invoice, setInvoice] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceSummery, setInvoiceSummery] = useState([]);
  const [emitionOffsetModalOpen, setEmitionOffsetModalOpen] = useState(false);

  const [carbonNutralisedShipemnts, setCarbonNutralisedShipemnts] = useState(
    []
  );

  const handleOpenUnpdInvoice = (invNo, CompName, weekNo) => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenInvoice?InvoiceId=5&MemberCode=1003&unique=1`
        `${APIBASE_URL}/GreenInvoice?MemberCode=${MemberCode}&InvoiceId=${invNo}&unique=1`
      )
      .then((res) => {
        console.log(res.data);
        const carriers = res.data;
        const gridData = carriers.map((el) => {
          return {
            ...el,
            id: el.InvoiceId,
          };
        });
        setInvoiceSummery(gridData);
        console.log(gridData);
      })
      .catch(() => {
        setInvoiceSummery([]);
      });
    setEmitionOffsetModalOpen(true);
    console.log("hi koustabh... how are you?");
  };

  const classes = useStyles();

  const gridReady = (params) => {
    gridApi.current = params.api;
    // this.gridColumnApi = params.columnApi;
    gridApi.current.sizeColumnsToFit();
  };
  const getRowStyle = (params) => {
    if (params.node.selected) {
      return { background: "#174C38" };
    }
    return null;
  };

  useEffect(() => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenShipments?MemberCode=1005&ShipmentType=Y`
        `${APIBASE_URL}/GreenInvoice?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);

        setInvoiceDetails(res.data[0]);
      })
      .catch(() => {
        setCarbonNutralisedShipemnts([]);
      });
  }, [APIBASE_URL, MemberCode]);

  const handleEmitionOffsetModalClose = () => {
    setEmitionOffsetModalOpen(false);
  };
  return (
    <>
      <Grid container>
        <Grid item sm={3} lg={3} xl={3}></Grid>
        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <Grid container>
            <Grid item sm={12} lg={12} xl={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                  <Typography variant="h6" className={classes.textSecondary}>
                    {MemberName}
                  </Typography>
                  <Card className={classes.minHght}>
                    <CardContent
                      style={{
                        display: "flex",
                        flex: "1",
                        flexDirection: "row",
                        alignItems: "center",
                        minHeight: "60px",
                        paddingBottom: "20px",
                        VerticalAlignCenter: "true",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item sm={4}>
                          <Typography variant="h6">
                            Total Payment Due
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            variant="h4"
                            color="textSecondary"
                            gutterBottom
                          >
                            {/* {formatter.format(totalDue)} */}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ width: 180, textTransform: "capitalize" }}
                            // onClick={() => setOpenGeminiInvoiceModal(true)}
                          >
                            Make Payment
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">
                        Pending Invoice(s) Details
                      </Typography>
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          marginTop: "15px",
                          overflow: "auto",
                        }}
                      >
                        {/* {allPendingInvoice.map((invoice, indx) => ( */}
                        {/* <Accordion key={indx}> */}
                        {invoiceDetails? (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                {" "}
                                For The Week no: {invoiceDetails?.InvoiceWeekNo}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={8} lg={8} xl={8}>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      Invoice No:{" "}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      {invoiceDetails?.InvoiceNumber}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      Invoice Year:{" "}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      {invoiceDetails?.InvoiceYear}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      Amount Due:
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      {" "}
                                      {invoiceDetails?.TotalAmount}
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      Total CO<sub>2</sub>:{" "}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      {invoiceDetails?.TotalCO2}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      Created On:{" "}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                    >
                                      {invoiceDetails?.InvoiceDate?.substring(
                                        0,
                                        10
                                      )}
                                    </Grid>
                                  </Grid>

                                  {/* <Grid container spacing={2}>
																			<Grid item xs={12} md={12}>
																				<Link href={invoice.InvoiceName} target="_blank">
																					Download Invoice <PictureAsPdf style={{ fill: 'brown' }} />
																				</Link> 
																			</Grid>
																		</Grid> */}
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4} xl={4}>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      width: 180,
                                      textTransform: "capitalize",
                                      margin: 7,
                                    }}
                                    onClick={(e) =>
                                      handleOpenUnpdInvoice(
                                        invoiceDetails?.InvoiceId,
                                        invoiceDetails?.Company,
                                        invoiceDetails?.InvoiceWeekNo
                                      )
                                    }
                                  >
                                    View Details
                                  </Button>{" "}
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      width: 180,
                                      textTransform: "capitalize",
                                      margin: 7,
                                    }}
                                    // onClick={() => setOpenGeminiInvoiceModal(true)}
                                  >
                                    Make Payment
                                  </Button>{" "}
                                  <br />
                                  <Link
                                    href={
                                      "http://app.greenabl.us" +
                                      invoiceDetails?.InvoiceFileNameAndPath
                                    }
                                    target="_blank"
                                    style={{ marginBottom: "auto" }}
                                  >
                                    Download Invoice{" "}
                                    <PictureAsPdf style={{ fill: "brown" }} />
                                  </Link>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ):'No Invoice to Show!'}
                        {/* ))} */}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* <Grid container spacing={3}>
								<Grid item xs={12} sm={12} lg={12} xl={12}>
									 <Typography className={classes.title} color="textSecondary" gutterBottom>
										Payment History
									</Typography> 
									 <Card className={classes.root}>
										<CardContent>
											<Typography variant="h6">Payment in Process</Typography>
											{allInProcessInvoice.length === 0 ? 'No Invoice Found' : null}
											{allInProcessInvoice.map((inProcInvo, index) => (
												<Accordion key={index}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1a-content"
														id="panel1a-header"
													>
														<Grid container spacing={5} key={index}>
															<Grid item xs={12} sm={4} lg={4} xl={4}>
																<Typography
																	className={classes.pos}
																	color="textSecondary"
																>
																	{inProcInvo.DueDate.split(' ')[0]}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={4} lg={4} xl={4}>
																<Typography color="textSecondary">
																	PayCargo Payment
																</Typography>
															</Grid>
															<Grid item xs={8} sm={3} lg={3} xl={3}>
																<Typography
																	className={classes.pos}
																	color="textSecondary"
																	style={{ float: 'center' }}
																>
																	{formatter.format(inProcInvo.AmountDue)}
																</Typography>
															</Grid>
														</Grid>
													</AccordionSummary>
													<AccordionDetails>
														<Grid item xs={12} sm={4} lg={4} xl={4}>
															<Typography color="textSecondary">
																<span className={classes.smallTxt}>
																	Payment Method:{' '}
																</span>
																<strong>Pay Cargo</strong>
																<br />
																<span className={classes.smallTxt}>
																	PayCargo Status:{' '}
																</span>
																<strong>Completed</strong>
															</Typography>
														</Grid>
													</AccordionDetails>
												</Accordion>
											))}
										</CardContent>
									</Card> 
								</Grid>
							</Grid> */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} lg={8} xl={8}>
                          <Typography variant="h6">Payment History</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4}>
                          {/* <FormControl
														variant="outlined"
														className={classes.formControl}
														size="small"
														style={{ float: 'right' }}
													>
														<InputLabel id="demo-simple-select-outlined-label">
															Year
														</InputLabel>
														<Select
															labelId="demo-simple-select-outlined-label"
															id="demo-simple-select-outlined"
															value={year}
															onChange={handleChange}
															label="year"
														>
															<MenuItem value={'2023'}>2023</MenuItem>
															<MenuItem value={'2022'}>2022</MenuItem>
															
														</Select>
													</FormControl> */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={6} xl={6}>
                          {/* <FormControl
														variant="outlined"
														className={classes.formControl}
														style={{ width: 270 }}
														size="small"
													>
														<InputLabel id="demo-simple-select-outlined-label">
															Payment Method
														</InputLabel>
														<Select
															labelId="demo-simple-select-outlined-label"
															id="demo-simple-select-outlined"
															value={pmethod}
															onChange={handleChange1}
															label="Payment Method"
														>
															<MenuItem value={1}>All</MenuItem>
															<MenuItem value={2}>PayCargo</MenuItem>
															
															<MenuItem value={3}>ACH</MenuItem>
														</Select>
													</FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={2} lg={2} xl={2}></Grid>
                      </Grid>
                      {/* {allPaidInvoiceFiltered.length === 0 ? 'No Invoice Found' : null}
											{allPaidInvoiceFiltered.map((Invoice, index) => ( */}
                      {/* <Accordion key={index}> */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid container spacing={5}>
                            <Grid item xs={12} sm={8} lg={8} xl={8}>
                              <Typography className={classes.heading}>
                                Week no: 38
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={4} lg={4} xl={4}>
																<Typography color="textSecondary">
																	PayCargo Payment
																</Typography>
															</Grid> */}

                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                              Invoice No: {/* {Invoice.InvoiceNumber} */}{" "}
                              MNOP9876
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} sm={8} lg={8} xl={8}>
                            {/* <Typography color="textSecondary">
																<span className={classes.smallTxt}>
																	Payment Method:{' '}
																</span>
																<strong>Pay Cargo</strong>
																<br />
																<span className={classes.smallTxt}>
																	Confirmation no:{' '}
																</span>
																{payCargoAccNo}
															</Typography> */}

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                Amount Paid:
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {" "}
                                $78.07
                                {/* {formatter.format(Invoice.AmountDue)} */}
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                Containers:{" "}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {/* {Invoice.CntrCnt} */} 3
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                Created On:{" "}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                09/17/2023
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                Due By:{" "}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                09/26/2023
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                Paid No:{" "}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                09/26/2023
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4} xl={4}>
                            <br />
                            <Link
                              // href={Invoice.InvoiceName}
                              target="_blank"
                              style={{ marginBottom: "auto" }}
                            >
                              Download Invoice{" "}
                              <PictureAsPdf style={{ fill: "brown" }} />
                            </Link>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      {/* ))} */}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Modal
        open={emitionOffsetModalOpen}
        onClose={handleEmitionOffsetModalClose}
        aria-labelledby="emition-offset-modal"
        aria-describedby="emition-offset-modal-description"
      >
        <div
          style={modalStyle}
          className={`${classes.paper} ${classes.largeWidth} ${classes.height}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2
              id="simple-modal-title"
              style={{ textAlignLast: "center" }}
            ></h2>

            <CloseButton onClose={handleEmitionOffsetModalClose} />
          </div>
          <Card style={{ marginBottom: 15 }}>
            <CardContent>
              <div
                style={{ height: 475, maxWidth: "100%", overflowX: "auto" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  columnDefs={CNcolumns}
                  rowData={invoiceSummery}
                  // onRowClicked={(e) => handleRowCLick(e.data)}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    background: "#174C38",
                  }}
                  onGridReady={gridReady}
                  rowSelection="single"
                  getRowStyle={getRowStyle}
                  // frameworkComponents={{
                  //   checkCellRendere: CheckRenderer,
                  //   checkArrivalRenderer: arrivalNoticeStatus,
                  // }}
                ></AgGridReact>
              </div>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default PaymentsContent;
