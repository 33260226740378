import React from 'react'
import logo from '../assets/img/Logo/svg/Greenabl Logo_inline_two-tone.svg'
import Mobilelogo from '../assets/img/Logo/svg/Greenabl Logo_Logo mark_two-tone.svg'

const Logo = () => {
  return (
    <div>
        <img src={logo} width='200' alt='logo' className='logo'  />
        <img src={Mobilelogo} height='40' alt='logo' className='mobileLogo' />

    </div>
  )
}

export default Logo