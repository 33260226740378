import React from 'react'
import Container from '../componants/Container'
import ResourcesContent from './resources/ResourcesContent'

const Resources = () => {
    return (
        <Container>
            <ResourcesContent />
        </Container>
    )
}

export default Resources