// import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import CompanyDetails from './CompanyDetails';
import OffsetPreference from './OffsetPreference';
import { ProjectLists } from './ProjectLists';

const MemberDetailsContent = () => {
  const [currentStep, setCurrentStep] = useState('step1');
  const [step2Data, setStep2Data] = useState([]);
  // const [step2Data3, setStep2Data3] = useState([]);
  // const [step3Data, setStep3Data] = useState([]);

  const onStepChange = (step, data2, data3) => {
    console.log(data2);
    console.log(data3);
    setStep2Data(data2);
    // setStep3Data(data3);
    setCurrentStep(step);
  };

  function goBack() {
    const lastChar = Number(currentStep.charAt(4));
    console.log(lastChar);
    let numeral = lastChar - 1;
    setCurrentStep('step' + numeral);
  }

  return (
    <>
      {/* <div style={{ position: 'absolute', top: 70, right: 20 }}>
        <Button
          disabled={currentStep === 'step1'}
          onClick={goBack}
          color="primary"
          variant="contained"
        >
          Back
        </Button>
      </div> */}
      <div className="member-details">
        {
          currentStep === 'step1' ? (
            <>
              <CompanyDetails />
              <OffsetPreference onStepChange={onStepChange} />
            </>
          ) : currentStep === 'step2' ? (
            <ProjectLists
              onStepChange={onStepChange}
              dataFromStepOne={step2Data}
              goback={goBack} 
              currentStep={currentStep}
            />
          ) : (
            ''
          )
          // (
          //   <ProjectDetailsView
          //     onStepChange={onStepChange}
          //     dataFromStepTwo={step3Data}
          //   />
          // )
        }
      </div>
    </>
  );
};

export default MemberDetailsContent;
