import React from "react";
import logomark from "../assets/img/Logo/svg/Greenabl Logo_Logo mark_full color.svg";

export const LogoMark = () => {
  return (
    <div
      style={{width: '-webkit-fill-available'}}
    >
      <img src={logomark} width="25" alt="logo" />
    </div>
  );
};
