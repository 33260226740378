import { makeStyles } from "@material-ui/core";
import React from "react";
import Drawer from "./Drawer";
import { Footer } from "./Footer";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginLeft: "80px",
    marginTop: "8px",
    marginBottom: 60,
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,

  },
}));

const Container = ({ children }) => {
  const classes = useStyles();
  return (
    <div >
      <Drawer />
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default Container;
