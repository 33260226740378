import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import MUIdrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Assignment,
  AttachMoney,
  Dashboard,
  DirectionsBoat,
  GroupAdd,
  LockOpen,
  Storage,
} from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
// import MailIcon from "@material-ui/icons/Mail";
import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { LogoMark } from "./LogoMark";
import { Profile } from "./profile";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    fontsize: 10,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

export default function Drawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState("Dashboard");
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const [companyDetails, setCompanyDetails] = useState({});

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  useEffect(() => {
    setCompanyDetails(JSON.parse(localStorage.getItem("MemberDetails")));
  }, [MemberCode]);

  const user = {
    data: {
      displayName: companyDetails[0]?.FName + " " + companyDetails[0]?.LName,
      photoURL: "../../assets/img/Robot Avatars_4.png",
      email: companyDetails[0]?.Email,
    },
    role: "Admin",
    company: "GreenaBL",
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changePage = (route, title, sub) => () => {
    // alert(title);
    setPage(title);
    localStorage.setItem("PageHeader", title);
    console.log(title);
    console.log(route);
    if (sub) {
      navigate("/" + route + "/" + sub);
    } else {
      navigate("/" + route);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <Profile />
        </Toolbar>
      </AppBar>
      <MUIdrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div className={classes.toolbar}>
          <LogoMark />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {open && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 5,
              gap: 6,
            }}
          >
            <Avatar alt="user photo" src={user.data.photoURL}>
              <AccountCircleIcon fontSize="large" />
            </Avatar>
            <Typography>{user.data.displayName}</Typography>
            <Typography style={{ fontSize: 13 }}>{user.data.email}</Typography>
          </div>
        )}

        <List>
          <Tooltip title="Dashboard">
            <ListItem button onClick={changePage("Dashboard", "Dashboard")}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Company Profile">
            <ListItem
              button
              onClick={changePage("MemberDetails", "Company Profile")}
            >
              <ListItemIcon>
                <GroupAdd />
              </ListItemIcon>
              <ListItemText primary="Company Profile" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Shipments">
            <>
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <Storage />
                </ListItemIcon>
                <ListItemText primary="Shipments" />
                {openSubMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={changePage(
                      "Shipment",
                      "Shipments / Carbon Non Neutralized ",
                      "CNN"
                    )}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="CO2 NN - FCL" />
                  </ListItem>
                  
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={changePage(
                      "Shipment",
                      "Shipments / Carbon Non Neutralized Shipments - LCL",
                      "LCLCNN"
                    )}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="CO2 NN - LCL" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={changePage(
                      "Shipment",
                      "Shipments / Carbon Non Neutralized Shipments - Air",
                      "LCLACNN"
                    )}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="CO2 NN - Air" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={changePage(
                      "Shipment",
                      "Shipments / Carbon Non Neutralized Shipments - Domestic",
                      "DOMCNN"
                    )}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="CO2 NN - Domestic" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={changePage(
                      "Shipment",
                      "Shipments / Carbon Neutralized ",
                      "CN"
                    )}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="CO2 Neutralized" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          </Tooltip>

          <Tooltip title="Resources">
            <ListItem button onClick={changePage("Resources", "Resources")}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText primary="Resources" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Carrier">
            <ListItem button onClick={changePage("carrierMaster", "Carrier Master")}>
              <ListItemIcon>
                <DirectionsBoat />
              </ListItemIcon>
              <ListItemText primary="Carrier" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Payments">
            <ListItem button onClick={changePage("Payments", "Payments")}>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Payments" />
            </ListItem>
          </Tooltip>
          <Divider />
          <Tooltip title="Login">
            <ListItem button onClick={changePage("Login", "Login")}>
              <ListItemIcon>
                <LockOpen />
              </ListItemIcon>
              <ListItemText primary="Login/Logout" />
            </ListItem>
          </Tooltip>
        </List>
      </MUIdrawer>
      <div
        style={{
          backgroundColor: "var(--secondaryGreen)",
          minHeight: 50,
          display: "flex",
          flexGrow: 1,
          marginTop: 64,
          paddingLeft: 42,
          color: "var(--neutralOffWhite)",
          boxShadow: "var(--shadow)",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontsize: 14, textShadow: "var(--shadow)" }}>
          {" "}
          {localStorage.getItem("PageHeader")}{" "}
        </Typography>
      </div>
    </div>
  );
}

// Greenabl Dashboard (Landing Page)
// 	- Reports
// 	- Offset Certificate (View and Download)

// Shipments (Date range and Grid showing emission status)
// 	- View Shipments (import shipments/uploaded)

// Greenabl Members
// 	- Member Details
// 	- Offset Preferences (Projects or Portfolios)
// Resources
// 	- News (Only for Greenabl members)
// 	- Documentation and help
// Payments
// 	- Greenabl Invoice
