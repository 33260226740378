import React from 'react';
import Container from "../componants/Container";
import CNNDomestic from './shipments/CNNDomestic';
import CarbonNeutralizedShipments from './shipments/CarbonNeutralizedShipments';
import CarbonNonNeutralizedShipments from './shipments/CarbonNonNeutralizedShipments';
import LCLShipments from './shipments/LCLShipments';
import LCLShipmentsAir from './shipments/LCLShipmentsAir';

const Shipment = ({sub}) => {

  return (
    <Container>
     
      {sub === 'CNN' && (

        <CarbonNonNeutralizedShipments />
      )}
      {sub === 'CN' && (

        <CarbonNeutralizedShipments />
      )}
      {sub === 'LCL-CNN' && (

       <LCLShipments />
      )}
      {sub === 'LCLA-CNN' && (

       <LCLShipmentsAir />
      )}
      {sub === 'DOM-CNN' && (

       <CNNDomestic />
      )}
    </Container>
  )
}

export default Shipment
