import React from "react";
import Typography from "@material-ui/core/Typography";


export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--secondaryGreen)",
        minHeight: 35,
        width:'100%',
        display: "flex",
        flexGrow: 1,
        color:"var(--tertiaryBlue3)",
        boxShadow: "var(--shadow)",
        justifyContent:'center',
        alignItems:'center',
        position:'fixed',
        left:0,
        bottom:0
      }}
    >
      <Typography style={{fontSize:12}}>&copy; Powered by VOYIX. All rights reserved. - Greenabl</Typography>
    </div>
  );
};
