import {
  Button,
  CardContent,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
// import React, { , useEffect, useState } from "react";
import DeleteCellRenderer from "./deleteCellRenderer";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },
  table: {
    minWidth: 500,
    height: 300,
    overflowY: "auto",
  },
  paperContainer1: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    padding: "35px",
    marginBottom: 20,
    "@media screen and (max-width: 576px)": {
      padding: 10,
    },
  },
  GridContainer: {
    minHeight: 356,
    minWidth: 900,
    // backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.6), rgba(255,255,255,.6)), url(${require("../assets/img/bg1.jpg")})`,
    backgroundPosition: "0,0",
    marginBottom: 20,
  },
  textSecondary: {
    color: "#ffffff",
  },
  //   smallWidth: {
  //     width: 400,
  //   },
  //   largeWidth: {
  //     width: 600,
  //   },
  //   progressWidth: {
  //     width: 300,
  //   },
  //   modal: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  memeberGrid: {
    display: "grid",
    gap: 10,
    gridTemplateColumns: "1fr",
    "@media screen and (max-width: 576px)": {
      gridTemplateColumns: "1fr",
    },
  },
}));

const editButton = (props) => {
  const handleEditClick = () => {
    console.log("hi", props.data);

    props.setCarrierDetails(props.data);
    props.setEdit(true);
  };
  return (
    <Button onClick={handleEditClick}>
      <Edit></Edit>
    </Button>
  );
};

const CarrierMasterContent = () => {
  const classes = useStyles();
  const gridRef = useRef();
  let gridApi = React.useRef(null);
  const APIBASE_URL = useMemo(() => process.env.REACT_APP_APIBASE_URL, []);
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const [carrierDetails, setCarrierDetails] = useState({
    Carrier: "",
    FullName: "",
    Alliance: "",
    SCAC: "",
    MemberCode: MemberCode,
  });
  const [addedNewCarr, setAddedNewCarr] = useState(false);
  const [carrierMasterList, setCarrierMasterList] = useState([]);
  const [mandatory, setMandatory] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const columns = [
    {
      field: "Carrier",
      headerName: "Carrier",
    },
    {
      field: "FullName",
      headerName: "Full Name",
    },
    {
      field: "Alliance",
      headerName: "Alliance",
    },
    {
      field: "SCAC",
      headerName: "SCAC",
    },
    {
      field: "SCAC",
      headerName: " ",
      cellRenderer: editButton,
      cellRendererParams: {
        setCarrierDetails,
        setEdit,
      },
      // pinned: "right",
      // lockPosition: "left",
      width: 60,
    },
    {
      field: "SCAC",
      headerName: " ",
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        update: updateData
        
      },
      // pinned: "right",
      // lockPosition: "left",
      width: 60,
    },
  ];

  useEffect(() => {
    console.log(MemberCode);
    axios
      .get(
        // `http://api.greenabl.us/api/GreenMember?MemberCode=1005`
        `${APIBASE_URL}/GreenCarrier?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);

        setCarrierMasterList(res.data);
        setAddedNewCarr(false);
      })
      .catch((err) => {
        console.log(err);
        // setCarrierMasterList();
      });
  }, [APIBASE_URL, MemberCode, addedNewCarr]);

  const handleCarrierChange = (e) => {
    setCarrierDetails({ ...carrierDetails, [e.target.name]: e.target.value });
    if (
      carrierDetails.Carrier !== "" &&
      carrierDetails.FullName !== "" &&
      carrierDetails.SCAC !== ""
    ) {
      setMandatory(false);
    }
  };
  const resetCarrDetails = () => {
    setCarrierDetails({ Carrier: "", FullName: "", Alliance: "", SCAC: "", MemberCode: MemberCode });
    setEdit(false);
    setMandatory(false);
  };

  function updateData() {
    console.log("updateData");
    setAddedNewCarr(true);
  }
  
  const addNewCarrier = () => {
    console.log(carrierDetails);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    if (edit) {
      axios
        .post(
          `${APIBASE_URL}/GreenCarrier?EditFlag=1`,
          carrierDetails,
          requestOptions
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data === 1) {
            console.log("Carrier Edited successfully!");
            setSeverity("success");
            setSuccMsg("Carrier Edited successfully!");
            setOpen(true);
            setAddedNewCarr(true);
            resetCarrDetails();
          }
          // if (resp.data === 0) {
          //   console.log("SCAC duplicate!");
          //   setSeverity("error");
          //   setSuccMsg("SCAC code already exist, Carrier could not be added!");
          //   setOpen(true);
          //   setAddedNewCarr(true);
          //   resetCarrDetails();
          // }
          // if (resp.data === 2) {
          //   console.log("Carrier name duplicate!");
          //   setSeverity("error");
          //   setSuccMsg("Carrier Name already exist, Carrier could not be added!");
          //   setOpen(true);
          //   setAddedNewCarr(true);
          //   resetCarrDetails();
          // }
        });
    } else {
      if (
        carrierDetails.Carrier !== "" &&
        carrierDetails.FullName !== "" &&
        carrierDetails.SCAC !== ""
      ) {
        axios
          .post(`${APIBASE_URL}/GreenCarrier`, carrierDetails, requestOptions)
          .then((resp) => {
            console.log(resp);
            if (resp.data === 1) {
              console.log("New Carrier added successfully!");
              setSeverity("success");
              setSuccMsg("New Carrier added successfully!");
              setOpen(true);
              setAddedNewCarr(true);
              resetCarrDetails();
            }
            if (resp.data === 0) {
              console.log("SCAC duplicate!");
              setSeverity("error");
              setSuccMsg(
                "SCAC code already exist, Carrier could not be added!"
              );
              setOpen(true);
              setAddedNewCarr(true);
              resetCarrDetails();
            }
            if (resp.data === 2) {
              console.log("Carrier name duplicate!");
              setSeverity("error");
              setSuccMsg(
                "Carrier Name already exist, Carrier could not be added!"
              );
              setOpen(true);
              setAddedNewCarr(true);
              resetCarrDetails();
            }
          });
      } else {
        setMandatory(true);
      }
    }
  };

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const gridReady = (params) => {
    gridApi.current = params.api;
    this.gridColumnApi = params.columnApi;
    gridApi.current.sizeColumnsToFit();
  };

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="member-details">
      <Card className={classes.paperContainer1}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 10,
            marginBottom: 15,
            borderRadius: 4,
          }}
        >
          <Typography variant="h5" component="h2" align="center">
            Carrier Master
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: 10,
            marginBottom: 15,
            borderRadius: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={10}>
              <Grid container spacing={1}>
                <Grid item sm={3}>
                  <TextField
                    id="carrier"
                    size="small"
                    variant="outlined"
                    value={carrierDetails.Carrier}
                    label="Carrier"
                    name="Carrier"
                    required
                    onChange={(e) => handleCarrierChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="fullname"
                    size="small"
                    variant="outlined"
                    value={carrierDetails.FullName}
                    label="Full Name"
                    name="FullName"
                    required
                    onChange={(e) => handleCarrierChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="allience"
                    size="small"
                    variant="outlined"
                    value={carrierDetails.Alliance}
                    label="Alliance"
                    name="Alliance"
                    onChange={(e) => handleCarrierChange(e)}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="scac"
                    size="small"
                    variant="outlined"
                    value={carrierDetails.SCAC}
                    label="SCAC"
                    name="SCAC"
                    required
                    onChange={(e) => handleCarrierChange(e)}
                    fullWidth
                    inputProps={{
                      maxLength: 4,
                      readOnly: edit,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  addNewCarrier();
                }}
                style={{ marginRight: 10 }}
              >
                {edit ? "Edit" : "Add"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetCarrDetails();
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          {mandatory && (
            <Grid container>
              <Grid item>* Marked fields are mandatory.</Grid>
            </Grid>
          )}
        </div>

        <div className={classes.memeberGrid}>
          <Card>
            <CardContent>
              <div className="example-header">
                Page Size:
                <select
                  onChange={onPageSizeChanged}
                  id="page-size"
                  style={{ marginLeft: 10 }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div
                style={{ height: 475, maxWidth: "100%", overflowX: "auto" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columns}
                  rowData={carrierMasterList}
                  rowSelection={"multiple"}
                  rowMultiSelectWithClick={false}
                  //   onSelectionChanged={onSelectionChanged}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    background: "#f0f0f5",
                    filter: "agTextColumnFilter",
                  }}
                  pagination={true}
                  paginationPageSize={50}
                  onGridReady={gridReady}
                  // frameworkComponents={{
                  //   checkCellRendere: CheckRenderer,
                  //   checkArrivalRenderer: arrivalNoticeStatus,
                  // }}
                ></AgGridReact>
              </div>
            </CardContent>
          </Card>
        </div>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {succMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CarrierMasterContent;
