import { Grid, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos } from "@material-ui/icons";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import axios from "axios";
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import certificateBack from "../../assets/img/greenCertificate.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },

  imageContainer: {
    // backgroundImage: `url(${
    //   process.env.PUBLIC_URL + "/Back_Projects_part1.png"
    // })`,
    height: 650,
    width: "100%",
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/greenCertificate.jpeg")})`,
    backgroundSize: "contain",
    position: "relative",
    backgroundRepeat: "no-repeat",
    // paddingTop: "77.25%", // 16:9
  },

  textContainer: {
    position: "absolute",
    top: "62.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    textAlign: "center",
    border: "0px solid red",
    width: "90%",
  },

  narrow: {
    marginTop: "0px",
    marginBottom: "5px",
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const ViewCertificate = ({
  CertificateDetails,
  ShipmentDetails,
  handleViewCerticateModalClose,
  CertificateCost,
  CertificateCo2e,
}) => {
  console.log(CertificateDetails);
  console.log(ShipmentDetails);
  const shipmentId = ShipmentDetails.GR_SH_ID;
  const certificateId = CertificateDetails.OffsetCertificateId;
  const content = useRef();
  //const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  // const MemberCode = 1005;
  // const MemberCode = useSelector((state) => state.memberreducer.MemberCode);

  const classes = useStyles();

  const direction = "up";
  const [open, setOpen] = React.useState(false);
  const hidden = false;
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const [paid, setPaid] = useState(false);
  // const MemberCode = useSelector((state) => state.memberreducer.MemberCode);

  // setCertificateDetails(CertificateDetails);

  useEffect(() => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenInvoice?ShipmentId=2555&CertificateId=88`
        `${APIBASE_URL}/GreenInvoice?ShipmentId=${shipmentId}&CertificateId=${certificateId}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data === "" || res.data === "Not Paid") {
          setPaid(false);
        }
        if (res.data === "Paid") {
          setPaid(true);
        }
      })
      .catch(() => {
        console.log("hi");
        setPaid(false);
      });
  }, [APIBASE_URL, certificateId, shipmentId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const DownloadPDF = () => {
    // return;
    console.log("pdf");
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      // format: [content.offsetWidth + 75, content.offsetHeight + 75]
    });
    let margin = 40;
    doc.html(content.current, {
      x: margin,
      y: margin + 10,
      html2canvas: {
        scale: 0.72,
      },
      callback: function (doc) {
        console.log("kol");
        doc.save(
          "carbon Nutralisation CertificateNo_" +
            CertificateDetails.OffsetCertificateId +
            ".pdf"
        );
      },
    });
  };

  const actions = [
    {
      icon: <ArrowBackIos onClick={handleViewCerticateModalClose} />,
      name: "Back",
    },
    {
      icon: (
        <IconButton disabled={paid}>
          <SaveIcon onClick={DownloadPDF} />
        </IconButton>
      ),
      name: "PDF",
    },

    {
      icon: (
        <IconButton disabled={!paid}>
          <PrintIcon />
        </IconButton>
      ),
      name: "Print",
    },
  ];
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <div
            ref={content}
            style={{ width: "100%", maxWidth: 1100, position: "relative" }}
          >
            <img
              src={certificateBack}
              height="700px"
              width="100%"
              object-fit="contain"
              alt="certificate background"
              border="1px solid green"
            />
            {!paid && (
              <Typography
                variant="h4"
                style={{
                  fontSize: 200,
                  color: "rgba(0,0,0,0.1)",
                  position: "fixed",
                  top: 250,
                  left: 320,
                  transform: "rotate(-45deg)",
                  zIndex: 1,
                }}
              >
                Pending
              </Typography>
            )}
            <div className={classes.textContainer}>
              <Typography
                variant="h4"
                style={{
                  color: "goldenrod",
                  marginBottom: 12,
                  marginLeft: "30%",
                }}
              >
                {CertificateDetails.MemberName}
              </Typography>

              <Grid container spacing={1}>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Container No</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    {ShipmentDetails.ContainerNo} {" [ "}
                    {ShipmentDetails.ContainerSize}
                    {" ]"}
                  </p>
                </Grid>
                {/* </Grid>
              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Carrier</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}> {ShipmentDetails.Carrier}</p>
                </Grid>
                {/* </Grid>
              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Lane</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    {ShipmentDetails.Origin} {" to "}
                    {ShipmentDetails.Destination}
                  </p>
                </Grid>
                {/* </Grid>

              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Project</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    {CertificateDetails.ProjectName}
                  </p>
                </Grid>
                {/* </Grid>
              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Project Location</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    {CertificateDetails.ProjectLocation}
                  </p>
                </Grid>
                {/* </Grid>
              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Project Type</p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    {CertificateDetails.ProjectType}
                  </p>
                </Grid>
                {/* </Grid>
              <Grid container spacing={2}> */}
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>
                    Total CO<sub>2</sub>e
                  </p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>{CertificateCo2e} MTon</p>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Registry </p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow} style={{ textAlign: 'left' }}>
                    {" "}
                    {certificateDetails.PRegistry}
                  </p>
                </Grid>
              </Grid> */}
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <p className={classes.narrow}>Price </p>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <p className={classes.narrow}>
                    {" "}
                    $
                    {CertificateDetails.EmmisionCost !== 0
                      ? CertificateCost
                      : "$XX.XX"}
                  </p>
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                style={{ fontSize: 11, fontWeight: "bold" }}
              >
                <br />
                New York, {CertificateDetails.CertificateDate.split("T")[0]}
              </Typography>

              <Typography
                variant="body2"
                style={{ fontSize: 11, textAlign: "center" }}
              >
                <br />
                Greenabl global transaction id:{" "}
                {CertificateDetails.OffsetCertificateTransactionId}
              </Typography>
            </div>
          </div>
          <div className={classes.exampleWrapper}>
            <SpeedDial
              ariaLabel="SpeedDial example"
              className={classes.speedDial}
              hidden={hidden}
              icon={<SpeedDialIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              direction={direction}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={handleClose}
                />
              ))}
            </SpeedDial>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ViewCertificate;
