import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";
import WidgetPieChart from './widgetPieChart';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    // backgroundImage:
      // "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },

  paperContainer: {
    height: 300,
    minWidth: 300,
    backgroundPosition: "0,0",
    backgroundColor: "#FFFFFF",
    marginBottom: 20,

  },
  textData: {
    color: "#1164B4",
    fontSize:"48px",
    fontWeight:"bold",
    textAlign: 'center',
  },

}));
const DashboardContent = () => {
  const classes = useStyles();
  // const chartRef = useRef(null);
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  // const MemberCode =  localStorage.getItem('MemberCode');
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const [greenscoreData, setGreenscoreData] = useState([]);
  const [co2eData, setCo2eData] = useState([]);
  const [totco2eData, setTotCo2eData] = useState([]);
  const [totco2perCrrData, setTotCo2perCrrData] = useState({});
  const [CO2PerTEUperCarrierData, setCO2PerTEUperCarrierData] = useState({});
  const [CO2OffsetPercentData, setCO2OffsetPercentData] = useState({});
  const [offsetDistByProj, setOffsetDistByProj] = useState({});
  const [offsetDistByProjType, setOffsetDistByProjType] = useState({});
  const [offsetDistByProjCountry, setOffsetDistByProjCountry] = useState({});
  const Dayspan = 365;
  console.log(MemberCode);
  const EmissionsperCarrier = {
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: totco2perCrrData?.crr
      }
    },
    series: [
      {
        name: "Total TEU",
        data: totco2perCrrData?.TTeu
      },
      {
        name: "Total CO2 / Carrier",
        data: totco2perCrrData?.TCO2
      },
      
    ]
  };

  const greenScore = {
    series: greenscoreData,
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Offset','Non-Offset'],
      colors:['#01E497','#028EF8'],
      tooltip: {
        enabled: true,
      },
      dataLabels: {
        enabled: true
      },
      
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          // offsetY: 0,
          // offsetX: 0,
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [{
        breakpoint: 800,
        options: {
          // chart: {
          //   width: 100,
          //   height:100
          // },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              donut: {
                size: '65%'
              }
            }
          }
        }
      },{
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
            height:300
          },
          legend: {
            position: 'bottom'
          }
        }
      },
      
    ],
      /* theme: {
        monochrome: {
          enabled: true,
          color: '#005c49',
        }
      }, */
      legend: {
        show: false
      }
    },
  };

  const TotalEmissionsperTEU = {
    title: 'Total Emissions per TEU by Carrier',
    series: [
      {
        name: 'Emissions/TEU',
        data: CO2PerTEUperCarrierData.CO2TEU
      }
    ],

    options: {
      chart: {
        type: 'bar',
        height: 400,
        width: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      /* theme: {
        monochrome: {
          enabled: true,
          color: '#005c49',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      }, */
      xaxis: {
        categories: CO2PerTEUperCarrierData.crr
      },
      yaxis: {
        title: {
          text: 'CO2e/TEU'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      }
    }
  }

  const CO2eOffsetsPercentage = {
    series: [CO2OffsetPercentData.compPcnt, CO2OffsetPercentData.pendPcnt],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      dataLabels: {
        enabled: false
      },
      labels: ['C02e Offset %', 'C02e Offset Pending %'],
      legend: {
        position: 'bottom',
        show: true,
      },
      fill: {
        type: 'gradient',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom',
            show: true,
          }
        }
      }]
    },
  };



  useEffect(() => {
    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?TileType=GS&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);
        setGreenscoreData([parseFloat(res.data, 10), (100 - parseFloat(res.data, 10))])
      })
      .catch((err) => {
        console.log(err);
      });
  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {
    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?TileType=TotCO2&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        setCo2eData(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?TileType=TotCO2pTEU&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        setTotCo2eData(res.data)
      })
      .catch((err) => {
        console.log(err);
      });

  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=TotCO2perCarrier&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
         console.log(res.data);
        const carriers = res.data.map(el => el.field1);
        const TotTEU = res.data.map(el => (parseFloat(el.field2, 10)));
        const TotCO2perCarrier = res.data.map(el => (parseFloat(el.field3).toFixed(2)));
        setTotCo2perCrrData({ crr: carriers, TTeu: TotTEU, TCO2: TotCO2perCarrier });
        // console.log(totco2perCrrData.crr);

      })
      .catch((err) => {
        console.log(err);
      });

  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=CO2PerTEUperCarrier&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);
        const carriers = res.data.map(res => res.field1);
        const co2perteu = res.data.map(res => parseFloat(res.field2).toFixed(2));

        // console.log(carriers);
        // console.log(co2perteu);
        setCO2PerTEUperCarrierData({ crr: carriers, CO2TEU: co2perteu });
      })
      .catch((err) => {
        console.log(err);
      });


  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=CO2eOffsetsPercentage&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        //field1:PName, field2:CO2eInMton

        setCO2OffsetPercentData({ compPcnt: parseFloat(res.data[0].field1), pendPcnt: parseFloat(res.data[0].field2) });
      })
      .catch((err) => {
        console.log(err);
      });



  }, [APIBASE_URL, MemberCode]);


  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=OffsetDistributionByProject&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        const PName = res.data.map(el => (el.field1));
        const CO2eInMton = res.data.map(el => (parseFloat(el.field2)));
        const newObj = { PName: PName, CO2eInMton: CO2eInMton }
        setOffsetDistByProj((prev) => ({ ...prev, data: newObj }));


      })
      .catch((err) => {
        console.log(err);
      });



  }, [APIBASE_URL,MemberCode]);


  useEffect(() => {

    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=OffsetDistributionByProjectType&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        const PNm = res.data.map(el => (el.field1));
        const CO2eInMt = res.data.map(el => (parseFloat(el.field2)));
        // console.log(PNm, CO2eInMt);
        const nwObj = { PName1: PNm, CO2eInMton1: CO2eInMt }

        setOffsetDistByProjType((prev) => ({ ProjType: nwObj }));
        // console.log(offsetDistByProjType);

      })
      .catch((err) => {
        console.log(err);
      });



  }, [APIBASE_URL,MemberCode]);

  useEffect(() => {
    axios
      .get(
        `${APIBASE_URL}/GreenDashboard?DataType=OffsetDistributionByCountry&ChType=1&days=${Dayspan}&MemberCode=${MemberCode}`
      )
      .then((res) => {
        // console.log(res.data);
        const PName = res.data.map(el => (el.field1));
        const CO2eInMton = res.data.map(el => (parseFloat(el.field2)));
        const newObj = { PName: PName, CO2eInMton: CO2eInMton }
        // console.log(newObj)
        setOffsetDistByProjCountry((prev) => ({ ...prev, data: newObj }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [APIBASE_URL,MemberCode]);

  
 
  const OffsetsDistributionByProject = {
    series: offsetDistByProj?.data?.CO2eInMton,
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: true
      },
      labels: offsetDistByProj?.data?.PName,
      legend: {
        position: 'bottom',
        show: true,
      },
      fill: {
        type: 'gradient',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom',
            show: true,
          }
        }
      }]
    },
  };


  const OffsetsDistributionByProjectType = {
    series: offsetDistByProjType?.ProjType?.CO2eInMton1,
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: true
      },
      labels: offsetDistByProjType?.ProjType?.PName1,
      legend: {
        position: 'bottom',
        show: true,
      },
      fill: {
        type: 'gradient',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom',
            show: true,
          }
        }
      }]
    },
  };
 


  const OffsetsDistributionByProjectCntr = {
    series: offsetDistByProjCountry?.data?.CO2eInMton,
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },
      dataLabels: {
        enabled: true
      },
      labels: offsetDistByProjCountry?.data?.PName,
      legend: {
        position: 'bottom',
        show: true,
      },
      fill: {
        type: 'gradient',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom',
            show: true,
          }
        }
      }]
    },
  };
  console.log(OffsetsDistributionByProjectCntr)
  console.log(OffsetsDistributionByProjectType)
  console.log(OffsetsDistributionByProject)
  console.log(CO2eOffsetsPercentage)
  console.log(TotalEmissionsperTEU)
  console.log(greenScore)
  console.log(EmissionsperCarrier)


  return (
    <>
      <Grid container spacing={2} style={{padding: '0px 25px 0px 0px'}}>
        <Grid item sm={12} >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card className={classes.paperContainer}>
                <CardContent style={{paddingBottom:40}}>
                  <Typography variant='h6' style={{ marginBottom: 25 }}>
                    Overall Green Score : 
                    <span style={{ color: '#1164B4' }}> { greenscoreData[0]}{ "%"} </span>
                  </Typography>
                  {greenscoreData && (

                    <ReactApexChart
                      options={greenScore.options}
                      series={greenScore.series}
                      type="donut"
                      width="100%"
                      style={{marginBottom:20}}
                    />
                  )}
                  
                  {/* <Typography variant='h4' style={{marginleft:"-250px", marginTop: '-100px', textAlign:"center" }}>
                    {greenscoreData[0]}{ "%"} 
                  </Typography> */}
                  </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card className={classes.paperContainer}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant='h6' style={{ marginBottom: 55 }}>
                    Total CO2e Emission [Mton]
                  </Typography>
                  <Typography className={classes.textData}>
                    {co2eData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card className={classes.paperContainer}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant='h6' style={{ marginBottom: 55 }}>
                    Emissions per TEU [Mton]
                  </Typography>
                  <Typography className={classes.textData}>
                    {totco2eData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card className={classes.paperContainer}>
                <CardContent >
                  <Typography variant='h6'>
                    Emissions per Carrier
                  </Typography>
                  {totco2perCrrData && (

                    <ReactApexChart
                      options={EmissionsperCarrier?.options}
                      series={EmissionsperCarrier?.series}
                      type="bar"
                      width="100%"
                      height="250"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card className={classes.paperContainer}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant='h6'>
                    Total Emissions per TEU by Carrier
                  </Typography>
                  {CO2PerTEUperCarrierData && (

                    <ReactApexChart
                      options={TotalEmissionsperTEU?.options}
                      series={TotalEmissionsperTEU?.series}
                      type="bar"
                      width="100%"
                      height="250"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>

          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.paperContainer} style={{height:350}}>
                <CardContent id="offsetByPcnt">
                  <Typography variant='subtitle1'>
                    CO2e Offset Percentage
                  </Typography>
                  {CO2OffsetPercentData && (
                    <WidgetPieChart data={CO2eOffsetsPercentage} />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.paperContainer}style={{height:350}}>
                <CardContent id="offsetByProj">
                  <Typography variant='subtitle1'>
                    Offset Distribution By Project
                  </Typography>
                  {offsetDistByProj && (
                    <WidgetPieChart data={OffsetsDistributionByProject} />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.paperContainer} style={{height:350}}>
                <CardContent id="offsetByType">
                  <Typography variant='subtitle1'>
                    Offset Distribution By Project Type
                  </Typography>
                  {offsetDistByProjType && (
                    <WidgetPieChart data={OffsetsDistributionByProjectType} />
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card className={classes.paperContainer}style={{height:350}}>
                <CardContent id="offsetByCntry">
                  <Typography variant='subtitle1'>
                    Offset Distribution By Country
                  </Typography>
                  {offsetDistByProjCountry && (
                    <WidgetPieChart data={OffsetsDistributionByProjectCntr} />
                  )}
                </CardContent>
              </Card>
            </Grid>



          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default DashboardContent
