import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteCellRenderer = (props) => {
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteShipment = () => {
    
    // http://api.greenabl.us/api/GreenShipments?ShipmentId=5
    axios
      .post(`${APIBASE_URL}/GreenShipments?ShipmentId=${props.value}`)
      .then((resp) => {
        console.log(resp);
        if (resp.data === "Deleted") {
          setOpen(false);
          props.update();
        }
      });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <DeleteIcon></DeleteIcon>
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Please confirm Delete action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this shipment? This action can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteShipment} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCellRenderer;
