import { useMediaQuery } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const ProjCatcolumns = [
  // { field: "id", headerName: "ID", width: 0,  },
  // {
  //   field: "GR_SH_ID",
  //   headerName: "GR SH ID",

  // },
  //{ field: "CN", headerName: "", width: 20 },
  {
    field: "ProjectTypeName",
    headerName: "Type of Project",
    width: 230,
  },
  {
    field: "PCountry",
    headerName: "Country",
    width: 150,
  },
  {
    field: "NoOfProjects",
    headerName: "No of Projects",
    width: 140,
  },
  {
    field: "Cost",
    headerName: "AvgCost($/MTon)",
    width: 140,
  },
];


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },
  table: {
    minWidth: 500,
    height: 300,
    overflowY: "auto",
  },
  paperContainer1: {
    width: 950,
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    padding: "35px",
    marginBottom: 20,
  },
  GridContainer: {
    minHeight: 356,
    minWidth: 900,
    backgroundPosition: "0,0",
    marginBottom: 20,
  },
  textSecondary: {
    color: "#ffffff",
  },
  smallWidth: {
    width: 400,
  },
  largeWidth: {
    width: 600,
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ProjectCategories = ({
  
  handleClose,
  projectTypeListModalOpen,
  onStepChange,
}) => {
  let gridApi = React.useRef(null);
  const classes = useStyles();
  const mounted = useRef(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);

  const [projectTypeList, setProjectTypeList] = useState([]);

  useEffect(() => {
    mounted.current = true;

    axios
      .get(
        // `http://api.greenabl.us/api/GreenMemberProject`
        `${APIBASE_URL}/GreenMemberProject`
      )
      .then((res) => {
        console.log(res.data);
        if (mounted.current) {
          const projTypeList = res.data;
          setProjectTypeList(projTypeList);
        }
      })
      .catch((err) => {
        console.log(err);
        // setProjectList();
      });

    return () => {
      mounted.current = false;
    };
  }, [APIBASE_URL, MemberCode]);

  const handleProjectTypeListModalClose = () => {
    handleClose(false);
  };

  // const handleViewProjectDetails = (id, cnt) => {
  //   const step2data = { id: id, country: cnt };
  //   // setProjectTypeListModalOpen(false);
  //   onStepChange("step2", step2data);
  // };

  const gridReady = (params) => {
    gridApi.current = params.api;
    // this.gridColumnApi = params.columnApi;
    gridApi.current.sizeColumnsToFit();
  };
  const getRowStyle = (params) => {
    if (params.node.selected) {
      return { background: "#174C38" };
    }
    return null;
  };

  const handleRowCLick = (data) => {
    console.log(data);
    const step2data = { id: data.ProjectTypeName, country: data.PCountry };
    onStepChange("step2", step2data);
  };

  return (
    <Modal
      aria-labelledby="project-List-modal-title"
      aria-describedby="project-List-modal-description"
      className={classes.modal}
      open={projectTypeListModalOpen}
      onClose={handleProjectTypeListModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      
    >
      <Fade in={projectTypeListModalOpen}>
        <div
          className={classes.paper}
          
          style={{
            height: 550,
            width: isMobile ? "100%" : "inherit",
            padding: 15,
          }}
        >
          <h2 id="transition-modal-title">Select Project Type</h2>
          <div
            style={{ height: 400, maxWidth: "100%", overflowX: "auto", width:680 }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              columnDefs={ProjCatcolumns}
              rowData={projectTypeList}
              onRowClicked={(e) => handleRowCLick(e.data)}
              defaultColDef={{
                resizable: true,
                sortable: true,
                background: "#174C38",
              }}
              onGridReady={gridReady}
              rowSelection="single"
              getRowStyle={getRowStyle}
              // frameworkComponents={{
              //   checkCellRendere: CheckRenderer,
              //   checkArrivalRenderer: arrivalNoticeStatus,
              // }}
            ></AgGridReact>
          </div>

          {/* 
          <TableContainer
            component={Paper}
            style={{ overflow: "auto", height: 400, width: '100%' }}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="Project type list table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Type of Project</StyledTableCell>
                  <StyledTableCell align="left" style={{width:'150px'}}>
                    Country
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{width:'80px'}} >
                    No of Projects
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectTypeList.map((project, index) => (
                  <StyledTableRow key={project.ProjectTypeId + "-" + index}>
                    <StyledTableCell component="th" scope="row">
                      <Link href="#" onClick={() => { handleViewProjectDetails(project.ProjectTypeName, project.PCountry) }}>
                        {project.ProjectTypeName}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link href="#" onClick={() => { handleViewProjectDetails(project.ProjectTypeName, project.PCountry) }}>
                        {project.PCountry}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {project.NoOfProjects}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </div>
      </Fade>
    </Modal>
  );
};
