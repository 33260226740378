import { createTheme } from "@material-ui/core/styles";
export default createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: {
      main: "#005c49",
    },
    secondary: {
      main: "#80E0A7",
    },
    error: {
      light: "#e57373",
      main: "#F05354",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#005c49",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    icon: {
      dark: "#c2c2c2",
    },
    action:{
      hover: "#e3eeeb",
    }
  },
  typography: {
    fontFamily: 'Gantari',
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    // fontColor: "rgba(0, 0, 0, 0.54)",
  },
});
