import { Grid, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CancelOutlined } from "@material-ui/icons";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import ViewCertificate from "./ViewCertificate";

const CNcolumns = [
  // { field: "id", headerName: "ID", width: 0,  },
  // {
  //   field: "GR_SH_ID",
  //   headerName: "GR SH ID",

  // },
  //{ field: "CN", headerName: "", width: 20 },
  {
    field: "Carrier",
    headerName: "Carrier",
    width: 120,
  },
  {
    field: "ContainerNo",
    headerName: "Container No",
    width: 180,
  },
  {
    field: "ContainerSize",
    headerName: "Container Size",
    width: 180,
  },
  // {
  //   field: "OriginLocCode",
  //   headerName: "Origin LocCode",
  //   width: 200,
  // },

  // {
  //   field: "DestLocCode",
  //   headerName: "Destination LocCode",
  //   width: 200,
  // },
  {
    field: "Origin",
    headerName: "Origin",
    width: 200,
  },
  {
    field: "Destination",
    headerName: "Destination",
    width: 250,
  },
  {
    field: "Vessel",
    headerName: "Vessel",
    width: 150,
  },
  // {
  //   field: "Voyage",
  //   headerName: "Voyage",
  //   width: 90,
  // },
  {
    field: "CO2Emission_in_grams",
    headerName: "CO2Emn(MTon)",
    width: 180,
  },
  {
    field: "InsertDate",
    headerName: "CC Add Date",
    width: 120,
    valueFormatter: (params) => {
      const dt = new Date(params.value);

      return new Intl.DateTimeFormat("en-US").format(dt);
    },
  },
];
const CloseButton = ({ onClose }) => {
  return (
    <IconButton
      aria-label="close"
      color="primary"
      onClick={onClose}
      style={{ float: "right" }}
    >
      <CancelOutlined />
    </IconButton>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },

  paperContainer1: {
    height: 356,
    minWidth: 900,
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/Back_Projects_part1.png")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    marginBottom: 20,
  },

  GridContainer: {
    minHeight: 356,
    margin: "0 20px",
    "@media screen and (max-width:576px)": {
      margin: "0",
    },
  },
  textSecondary: {
    color: "#ffffff",
  },
  textData: {
    color: "#1164B4",
    fontSize: "14px",
    fontWeight: "bold",
  },
  paper: {
    position: "absolute",
    backgroundColor: "var(--neutralOffWhite)",
    border: "2px solid #000",
    boxShadow: "var(--neutralGray)",
    padding: "0px 25px 15px 30px",
  },
  smallWidth: {
    width: 400,
  },
  mediumWidth: {
    width: 750,
  },
  largeWidth: {
    width: "90%",
    maxWidth: 1200,

    // "@media screen and (max-width:768px)": {
    //     width: "unset",
    //     maxWidth: 700
    // },
    // "@media screen and (max-width:1024px)": {
    //     width: "unset",
    //     maxWidth: 900
    // },
  },

  height: {
    height: 600,
    overflow: "auto",
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[9],
    padding: theme.spacing(2, 4, 3),
  },
  btn: {
    border: "1px solid #666",
    borderRadius: 0,
    padding: "15px 10px",
    marginBottom: "65px",
    textAlign: "center",
    cursor: "pointer",
  },
  media: {
    height: 50,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  smallBold: {
    fontSize: 12,
    fontWeight: "bold",
  },
  small: {
    fontSize: 9.5,
    fontWeight: "bold",
  },
  wordWrap: {
    wordWrap: "break-word",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const CarbonNeutralizedShipments = () => {
  const classes = useStyles();
  // const mounted = useRef(true);
  // const gridRef = useRef();
  let gridApi = React.useRef(null);
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  // const uId = localStorage.getItem("uId");
  // const MemberCode = localStorage.getItem("MemberCode");
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);

  const [modalStyle] = React.useState(getModalStyle);
  const [cost, setCost] = useState(0);
  const [co2e, setCo2e] = useState(0);
  // const [projectListDetails, setProjectListDetails] = useState([]);
  // const [shipments, setShipments] = useState([]);
  // const [sudoshipments, setSudoShipments] = useState([]);
  const [selectedshipmentId, setSelectedshipmentId] = useState(0);
  const [totalCO2, setTotalCO2] = useState(0);
  // const [addShipmentSuccess, setAddShipmentSuccess] = useState(false);
  const [certificateIdforView, setCertificateIdforView] = useState();
  const [carbonNutralisedShipemnts, setCarbonNutralisedShipemnts] = useState(
    []
  );
  // const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [shipmentMoreDetails, setShipmentMoreDetails] = useState([]);
  const [offsetDetails, setOffsetDetails] = useState([]);
  // const [OffsetCerticateModalOpen, setOffsetCerticateModalOpen] = useState(false);
  const [viewCerticateModalOpen, setViewCerticateModalOpen] = useState(false);
  // const [OffsetSuccessModalOpen, setOffsetSuccessModalOpen] = useState(false);
  const [emitionOffsetModalOpen, setEmitionOffsetModalOpen] = useState(false);

  const gridReady = (params) => {
    gridApi.current = params.api;
    // this.gridColumnApi = params.columnApi;
    gridApi.current.sizeColumnsToFit();
  };
  const getRowStyle = (params) => {
    if (params.node.selected) {
      return { background: "#174C38" };
    }
    return null;
  };

  useEffect(() => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenShipments?MemberCode=1005&ShipmentType=Y`
        `${APIBASE_URL}/GreenShipments?MemberCode=${MemberCode}&ShipmentType=Y`
      )
      .then((res) => {
        console.log(res.data);
        const carriers = res.data;
        const nwCrr = carriers.map((el) => {
          return {
            ...el,
            id: el.GR_SH_ID,
          };
        });
        setCarbonNutralisedShipemnts(nwCrr);
        console.log(nwCrr);
      })
      .catch(() => {
        setCarbonNutralisedShipemnts([]);
      });
  }, [APIBASE_URL, MemberCode]);

  // const handleOffsetCerticateModalOpen = () => {
  //   setOffsetCerticateModalOpen(true);
  //   setOffsetSuccessModalOpen(false);
  // };

  const handleDownloadCNS = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(carbonNutralisedShipemnts);

    XLSX.utils.book_append_sheet(wb, ws, "Carbon nuetralized Shipment");
    XLSX.writeFile(wb, "Carbon-nuetralized-ShipmentDetails.xlsx");
  };

  const viewCertificate = (id) => {
    console.log(id);
    setCertificateIdforView(id);
    handleViewCerticateModalOpen();
    setCost(
      (
        (offsetDetails[id].EmmisionCost / offsetDetails[id].CO2) *
        (totalCO2 / offsetDetails.length)
      ).toFixed(2)
    );
    setCo2e((totalCO2 / offsetDetails.length).toFixed(2));
  };

  const handleViewCerticateModalOpen = () => {
    setViewCerticateModalOpen(true);
    // setOffsetSuccessModalOpen(false);
  };

  const handleViewCerticateModalClose = () => {
    setViewCerticateModalOpen(false);
  };
  // const handleOffsetCerticateModalClose = () => {
  //   setOffsetCerticateModalOpen(false);
  // };

  const handleRowCLick = (data) => {
    console.log(data);
    setSelectedshipmentId(data.GR_SH_ID);
    setTotalCO2(data.CO2Emission_in_grams);
  };

  const handleEmitionOffsetModalOpen = () => {
    console.log(selectedshipmentId);
    axios
      .get(
        // `http://api.greenabl.us/api/GreenOffsetShipment?ShipmentId=7`
        `${APIBASE_URL}/GreenOffsetShipment?ShipmentId=${selectedshipmentId}`
      )
      .then((res) => {
        console.log(res.data);
        const shipmentDetails = res.data;
        setShipmentMoreDetails(shipmentDetails);
        console.log(shipmentDetails);
        axios
          .get(
            // `http://api.greenabl.us/api/GreenOffsetShipment?ShipmentId=7`
            `${APIBASE_URL}/GreenOffsetCertificate?ShipmentId=${selectedshipmentId}`
          )
          .then((response) => {
            console.log(response.data);
            const shipmentDetails = response.data;
            setOffsetDetails(shipmentDetails);
            console.log(shipmentDetails);
          })
          .catch(() => {
            setOffsetDetails([]);
          });
      })
      .catch(() => {
        setShipmentMoreDetails([]);
      });
    setEmitionOffsetModalOpen(true);
  };
  const handleEmitionOffsetModalClose = () => {
    setEmitionOffsetModalOpen(false);
  };
  return (
    <>
      <Card className={classes.GridContainer}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <Typography variant="h5">Carbon Neutralized Shipments</Typography>
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{ width: 180, textTransform: "capitalize", margin: 7 }}
              onClick={handleEmitionOffsetModalOpen}
            >
              View Details
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleDownloadCNS}
              disabled={carbonNutralisedShipemnts.length === null}
            >
              <SystemUpdateAltIcon></SystemUpdateAltIcon>
            </Button>
          </div>
        </CardContent>

        <CardContent>
          <div
            style={{ height: 475, maxWidth: "100%", overflowX: "auto" }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              columnDefs={CNcolumns}
              rowData={carbonNutralisedShipemnts}
              onRowClicked={(e) => handleRowCLick(e.data)}
              defaultColDef={{
                resizable: true,
                sortable: true,
                background: "#174C38",
              }}
              onGridReady={gridReady}
              rowSelection="single"
              getRowStyle={getRowStyle}
              // frameworkComponents={{
              //   checkCellRendere: CheckRenderer,
              //   checkArrivalRenderer: arrivalNoticeStatus,
              // }}
            ></AgGridReact>
          </div>
        </CardContent>
      </Card>

      {/* <Modal
                open={OffsetCerticateModalOpen}
                onClose={handleOffsetCerticateModalClose}
                aria-labelledby="emition-offset-success-modal"
                aria-describedby="emition-offset-success-modal-description"
            >
                <div
                    style={modalStyle}
                    className={`${classes.paper} ${classes.largeWidth}`}
                >
                    <h2 id="simple-modal-title" style={{ textAlignLast: "center" }}>
                        Select to view from following Certificate(s):
                    </h2>
                    {selectedCertificates.map((id, i) => (
                        <CardContent
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 10,
                                marginTop: "16px",
                            }}
                        >
                            <h2
                                id="transition-modal-description"
                                style={{ textAlign: "center" }}
                            >
                                <Link
                                    href="#"
                                    onClick={() => {
                                        viewCertificate(selectedCertificates[i]);
                                    }}
                                    style={{ color: "blue" }}
                                >
                                    GreenaBl Certificate No: {selectedCertificates[i]}
                                </Link>
                            </h2>
                        </CardContent>
                    ))}
                </div>
            </Modal> */}

      <Modal
        open={viewCerticateModalOpen}
        onClose={handleViewCerticateModalClose}
        aria-labelledby="emition-offset-success-modal"
        aria-describedby="emition-offset-success-modal-description"
      >
        <div
          style={modalStyle}
          className={`${classes.paper} ${classes.largeWidth}`}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 10,
              marginTop: "16px",
            }}
          >
            <ViewCertificate
              CertificateDetails={offsetDetails[certificateIdforView]}
              ShipmentDetails={shipmentMoreDetails[0]}
              handleViewCerticateModalClose={handleViewCerticateModalClose}
              CertificateCost={cost}
              CertificateCo2e={co2e}
            />
          </CardContent>
        </div>
      </Modal>
      <Modal
        open={emitionOffsetModalOpen}
        onClose={handleEmitionOffsetModalClose}
        aria-labelledby="emition-offset-modal"
        aria-describedby="emition-offset-modal-description"
      >
        <div
          style={modalStyle}
          className={`${classes.paper} ${classes.largeWidth} ${classes.height}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 id="simple-modal-title" style={{ textAlignLast: "center" }}>
              Shipment Details for Container# -{" "}
              {shipmentMoreDetails[0]?.ContainerNo +
                " [" +
                shipmentMoreDetails[0]?.ContainerSize +
                "]"}
            </h2>

            <CloseButton onClose={handleEmitionOffsetModalClose} />
          </div>
          <Card style={{ marginBottom: 15 }}>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowX: "auto",
              }}
            >
              {/* <Typography variant="h6">Shipment Details:</Typography> */}

              <Grid container spacing={2}>
                <Grid item sx={12} sm={4}>
                  Carrier:{" "}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.Carrier}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  Origin:{" "}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.Origin}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  {shipmentMoreDetails[0]?.TransPort !== ""
                    ? "Transshipment Port "
                    : ""}{" "}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.TransPort}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sx={12} sm={4}>
                  BL Type:{" "}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.BLType}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  Destination:{" "}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.Destination}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  {shipmentMoreDetails[0]?.DischargePort !== ""
                    ? "Discharge Port:"
                    : ""}
                  <span className={classes.textData}>
                    {" "}
                    {shipmentMoreDetails[0]?.DischargePort}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sx={12} sm={4}>
                  Vessel/Voyage:{" "}
                  <span className={classes.textData}>
                    {shipmentMoreDetails[0]?.Vessel
                      ? shipmentMoreDetails[0]?.Vessel +
                        "/" +
                        shipmentMoreDetails[0]?.Voyage
                      : ""}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  {shipmentMoreDetails[0]?.LoadPort !== "" ? "Load Port: " : ""}{" "}
                  <span className={classes.textData}>
                    {shipmentMoreDetails[0]?.LoadPort}
                  </span>
                </Grid>
                <Grid item sx={12} sm={4}>
                  {shipmentMoreDetails[0]?.InlandCYRamp !== ""
                    ? "Inland CY/Ramp: "
                    : ""}
                  <span className={classes.textData}>
                    {shipmentMoreDetails[0]?.InlandCYRamp}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sx={12} sm={4}>
                  Offset CO<sub>2</sub>e (MTon):{" "}
                  <span className={classes.textData}>{totalCO2}</span>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "left",
                gap: 8,
                marginTop: "16px",
              }}
            >
              <Typography variant="h6">Offset Project(s):</Typography>
              <Grid container spacing={2}>
                {offsetDetails &&
                  offsetDetails.map((projDtls, index) => (
                    <Grid item sx={12} sm={6} md={4} lg={3} key={index}>
                      <Card>
                        <CardHeader
                          style={{
                            maxHeight: 100,
                            minHeight: 100,
                            verticalAlign: "top",
                          }}
                          title={projDtls.ProjectName.substring(0, 35)}
                          // subheader={projDtls.ProjectLocation.split(/[\s,]+/).pop()}
                          subheader={projDtls.ProjectLocation.substring(
                            projDtls.ProjectLocation.indexOf(",") + 1,
                            100
                          )}
                          avatar={
                            <Tooltip title={projDtls.ProjectName}>
                              <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                {projDtls.ProjectName[0]}
                              </Avatar>
                            </Tooltip>
                          }
                        />
                        <CardMedia
                          className={classes.media}
                          image={projDtls.imgUrl}
                        />
                        <CardContent style={{ minHeight: 120 }}>
                          <Typography variant="body2">
                            Offset CO<sub>2</sub>e (MTon):{" "}
                            <span className={classes.smallBold}>
                              {(totalCO2 / offsetDetails.length).toFixed(2)}
                            </span>
                            <br />
                            Type:{" "}
                            <span className={classes.smallBold}>
                              {projDtls.ProjectType}
                            </span>
                            <br />
                            Registry:{" "}
                            <span className={classes.smallBold}>
                              {projDtls.PRegistry.substring(0, 20) + "..."}
                            </span>
                            <br />
                            Cost: $
                            <span className={classes.smallBold}>
                              {projDtls.EmmisionCost === 0
                                ? "$XX.XX"
                                : (
                                    (projDtls.EmmisionCost / projDtls.CO2) *
                                    (totalCO2 / offsetDetails.length)
                                  ).toFixed(2)}
                            </span>
                            <br />
                            Transaction Id:{" "}
                            <span
                              className={`${classes.small} ${classes.wordWrap}`}
                            >
                              {projDtls.OffsetCertificateTransactionId}
                            </span>
                          </Typography>
                        </CardContent>
                        <CardContent>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ width: 180, textTransform: "capitalize" }}
                            onClick={() => {
                              viewCertificate(index);
                            }}
                          >
                            View Certificate
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
            {/* <h4 id="simple-modal-title" style={{alignItems: "left",  marginLeft: "16px",}}>  </h4> */}
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default CarbonNeutralizedShipments;
