import DateFnsUtils from "@date-io/date-fns";
import { Grid, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos, CloudCircle } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import html2PDF from "jspdf-html2canvas";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import { jsPDF } from "jspdf";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeMembercode } from "../Action/memberAction";
import logo from "../assets/img/Logo/png/Greenabl Logo_stacked_full color.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalBtn: {
    marginTop: "10px",
    float: "right",
    color: "primary",
  },
  speedDial: {
    position: "absolute",
    // "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    //   bottom: theme.spacing(1),
    //   right: theme.spacing(1),
    // },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

export const Profile = () => {
  const dispatch = useDispatch();
  const [userMenu, setUserMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSpDl, setOpenSpDl] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [openFileUp, setOpenFileUp] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [memberNamesInDB, setmemberNamesInDB] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(moment().format("MM/DD/YYYY"));
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const content = useRef();
  const navigate = useNavigate();
  const user = {
    data: {
      displayName: localStorage.getItem("UserName"),
      photoURL: "../../assets/img/Robot Avatars_4.png",
    },
    role: localStorage.getItem("usr_role"),
    company: localStorage.getItem("Name"),
  };

  const userAvatarSrc = user.data.photoURL;
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const uId = localStorage.getItem("LKey");
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  //console.log (MemberCode);
  const usr_role = localStorage.getItem("usr_role");
  const [uCompany, setuCompany] = useState();
  const [MemberName, setMemberName] = useState();
  const classes = useStyles();

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };
  // const invoiceMenuClose = () => {
  //   setUserMenu(null);
  // };
  const userMenuLogout = () => {
    setUserMenu(null);
    localStorage.clear();
    navigate("/Login");
  };
  const handleOpen = () => {
    setOpen(true);
    userMenuClose();
  };
  const handleOpenInvoice = () => {
    setOpenInvoice(true);
    userMenuClose();
  };
  const handleShowInvoice = () => {
    setOpenInvoice(false);
    setShowInvoice(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("PageHeader", "User Profile");
    navigate("/MemberDetails");
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };
  const handleCloseShowInvoice = () => {
    setShowInvoice(false);
  };

  const handleCloseSpDl = () => {
    setOpenSpDl(false);
  };

  const handleOpenSpDl = () => {
    setOpenSpDl(true);
  };

  const handleOpenFileUp = () => {
    setOpenFileUp(true);
  };
  const handleCloseFileUp = () => {
    setOpenFileUp(false);
  };
  const changePage = (route, title, sub) => () => {
    localStorage.setItem("PageHeader", title);
    console.log(title);
    console.log(route);
    if (sub) {
      navigate("/" + route + "/" + sub);
    } else {
      navigate("/" + route);
    }
  };
  useEffect(() => {
    if (usr_role >= 50) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
    axios
      .get(
        APIBASE_URL + "/GreenMember?loginkey=" + uId + "&UserRole=" + usr_role
      )
      .then((res) => {
        console.log("sorry: ", res);
        setmemberNamesInDB(res.data);
      });
  }, [APIBASE_URL, uId, usr_role]);

  const onSelectMember = (e, value) => {
    setMemberName(value);
    setuCompany(value.Company);

    console.log("Hello", value);
  };

  const confirmSelectedMember = () => {
    
    localStorage.setItem("Name", MemberName.Company);
    localStorage.setItem("Lkey", MemberName.LoginKey);
    localStorage.setItem("UserName", MemberName.UserName);
    localStorage.setItem("MemberCode", MemberName.Clientcode);
    dispatch(changeMembercode(MemberName.Clientcode));
    setuCompany(MemberName.Company);
    setMemberName(null);
    // handleClose();
    setOpen(false);
  };

  const generateInvoice = () => {
    // http://api.greenabl.us/api/GreenInvoice?MemberCode=5150&InvoiceDate=04/03/2023&InvoiceType=M
    axios
      .post(
        APIBASE_URL +
          "/GreenInvoice?MemberCode=" +
          MemberName.Clientcode +
          "&InvoiceDate=" +
          invoiceDate +
          "&InvoiceType=" +
          MemberName.InvoiceType
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("Invoice: ", res.data[0]);
          setInvoiceDetails(res.data[0]);
          handleShowInvoice();
        }
      });

    console.log("invoive is generated");

    setOpenInvoice(false);
  };
  const handleDateChange = (date) => {
    console.log(moment(date).format("MM/DD/YYYY"));
    setInvoiceDate(moment(date).format("MM/DD/YYYY"));
  };

  const DownloadPDF = () => {
    console.log("pdf");
    const filename =
      uCompany.replace(",", "-") + "-" + invoiceDetails.InvoiceNumber + ".pdf";
    console.log(filename);
    html2PDF(document.querySelector("#pdfContent"), { scale: "2" }).then(
      (canvas) => {
        canvas.save(filename);
      }
    );
    //return;

    // const doc = new jsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });
    // let margin = 40;
    // doc.html(content.current, {
    //   x: margin,
    //   y: margin + 10,
    //   html2canvas: {
    //     scale: 0.72,
    //   },
    //   image: { type: "jpeg", quality: 0.98 },
    //   callback: function (doc) {
    //     console.log("kol");
    //     doc.save(
    //       uCompany.replace(",", "-") +
    //         "-" +
    //         invoiceDetails.InvoiceNumber +
    //         ".pdf"
    //     );
    //   },
    // });
  };

  const direction = "up";

  const hidden = false;
  const actions = [
    {
      icon: <ArrowBackIos onClick={handleCloseShowInvoice} />,
      name: "Back",
    },
    {
      icon: (
        <IconButton>
          <SaveIcon onClick={DownloadPDF} />
        </IconButton>
      ),
      name: "PDF",
    },

    {
      icon: (
        <IconButton>
          <CloudCircle onClick={handleOpenFileUp} />
        </IconButton>
      ),
      name: "Print",
    },
  ];

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const fileChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const confirmFileSubmit = () => {
    // Check if a file is selected
    let innerHTML = "";
    if (isFilePicked) {
      const subfolderName = "GreenaBL-invoices"; //uCompany;
      if (subfolderName) {
        const formData = new FormData();
        formData.append(selectedFile.name, selectedFile);
        try {
          const apiUrl = `https://api.gshippersgroup.com/api/FileUpload?subFolderName=${subfolderName}`;
          const bodyParam = {
            method: "POST",
            body: formData,
          };
          fetch(apiUrl, bodyParam).then((response) => {
            console.log(response);
            if (response.ok) {
              // File uploaded successfully
              const responseBody = response.text();
              innerHTML = `<p style="color: green;">File uploaded successfully. Response: ${responseBody}</p>`;
              console.log(innerHTML);
              alert("File uploaded successfully");
              handleCloseFileUp();
              handleCloseShowInvoice();
            } else {
              // Handle errors
              innerHTML = `<p style="color: red;">Error uploading file. Status code: ${response.status}</p>`;
              console.log(innerHTML);
            }
          });
        } catch (error) {
          console.error("An error occurred:", error);
          innerHTML = `<p style="color: red;">An error occurred: ${error.message}</p>`;
          console.log(innerHTML);
        }
      } else {
        innerHTML = '<p style="color: red;">Please enter a subfolder name.</p>';
        console.log(innerHTML);
      }
    } else {
      innerHTML = '<p style="color: red;">Please select a file to upload.</p>';
      console.log(innerHTML);
    }
  };

  return (
    <>
      <Button
        style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
        onClick={userMenuClick}
      >
        <div style={{ color: "var(--neutralOffWhite)", marginRight: 8 }}>
          <Typography component="span" style={{ fontSize: 13 }}>
            {user.data.displayName}
          </Typography>
          <Typography style={{ fontSize: 13 }} className="compName">
            {user.company}
            {(!user.role ||
              (Array.isArray(user.role) && user.role.length === 0)) &&
              "Guest"}
          </Typography>
        </div>

        <Avatar className="md:mx-4" alt="user photo" src={userAvatarSrc}>
          {/* <img src="assets/images/avatars/profile.jpg" /> */}
          <AccountCircleIcon fontSize="large" />
        </Avatar>
        {/* {user.data.photoURL ? (
					<Avatar className="md:mx-4" alt="user photo" src={userAvatarSrc} />
					

				) : (
					<Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
				)} */}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login" role="button">
              <ListItemIcon className="min-w-40">
                <Icon color="primary">lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon color="primary">person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              // component={Link}
              // to="/Home"
              onClick={changePage("UserProfile", "User Profile")}
              role="button"
            >
              <ListItemIcon
                className="min-w-40"
                onClick={changePage("UserProfile", "User Profile")}
              >
                <Icon color="primary">account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="Show My Profile " />
            </MenuItem>
            {admin && (
              <>
                <MenuItem onClick={handleOpen} role="button">
                  <ListItemIcon className="min-w-40">
                    <Icon color="primary">check_box</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Select Member" />
                </MenuItem>
                <MenuItem onClick={handleOpenInvoice} role="button">
                  <ListItemIcon className="min-w-40">
                    <Icon color="primary">description</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Generate Invoice" />
                </MenuItem>
              </>
            )}
            {/* <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>mail</Icon>
							</ListItemIcon>
							<ListItemText primary="Inbox" />
						</MenuItem> */}
            <MenuItem
              onClick={() => {
                // dsispatch(logoutUser());
                userMenuLogout();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon color="primary">exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Autocomplete
              id="member-select"
              options={memberNamesInDB}
              getOptionLabel={(option) =>
                option.Company + " - [" + option.Clientcode + "]"
              }
              renderOption={(option) => (
                <React.Fragment>
                  {option.Company} - [{option.Clientcode}]
                </React.Fragment>
              )}
              style={{ width: 300 }}
              autoSelect={true}
              renderInput={(params) => (
                <TextField {...params} label="Select Member" />
              )}
              onChange={onSelectMember}
            />
            <Button
              onClick={confirmSelectedMember}
              className={classes.modalBtn}
              disabled={!MemberName}
            >
              <Typography component="span" className="font-semibold flex ">
                Select
              </Typography>
            </Button>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="generate-invoice-modal"
        aria-describedby="generate-invoice-modal-description"
        className={classes.modal}
        open={openInvoice}
        onClose={handleCloseInvoice}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInvoice}>
          <div className={classes.paper}>
            <Grid container>
              <Grid item sx={12} sm={6} md={6} lg={6}>
                {" "}
                <Autocomplete
                  id="member-select"
                  options={memberNamesInDB}
                  getOptionLabel={(option) =>
                    option.Company + " - [" + option.Clientcode + "]"
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.Company} - [{option.Clientcode}]
                    </React.Fragment>
                  )}
                  fullWidth
                  style={{ width: 300 }}
                  autoSelect={true}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Member" />
                  )}
                  onChange={onSelectMember}
                />
              </Grid>
              <Grid item sx={12} sm={6} md={6} lg={6}>
                {" "}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-pol"
                    label="Invoice Date"
                    format="MM/dd/yyyy"
                    value={invoiceDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    fullWidth
                    disableFuture={true}
                    style={{
                      marginTop: 0,
                      paddingTop: 0,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sx={12} sm={12} md={12} lg={12}>
                {" "}
                <Button
                  onClick={generateInvoice}
                  className={classes.modalBtn}
                  disabled={!MemberName}
                >
                  <Typography component="span" className="font-semibold flex ">
                    Generate
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="Show-invoice-modal"
        aria-describedby="Show-invoice-modal-description"
        className={classes.modal}
        open={showInvoice}
        onClose={handleCloseShowInvoice}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showInvoice}>
          <>
            <div
              className={classes.paper}
              style={{ width: "720px" }}
              ref={content}
              id="pdfContent"
            >
              <Grid container style={{ borderBottom: "2px solid black" }}>
                <Grid item sx={12} sm={4} md={4} lg={4}>
                  <p style={{ alignItems: "flex-start" }}>
                    {" "}
                    Confidential Information
                  </p>
                </Grid>
                <Grid item sx={12} sm={4} md={4} lg={4}>
                  <h2 style={{ textAlign: "center" }}> Invoice</h2>
                </Grid>
                <Grid item sx={12} sm={4} md={4} lg={4}>
                  <p style={{ textAlign: "right" }}>Date: {invoiceDate}</p>
                </Grid>

                <Grid item sx={12} sm={10} md={10} lg={10}>
                  <img
                    src={logo}
                    alt="logo"
                    width="30%"
                    align="left"
                    style={{ paddingBottom: 15 }}
                  />
                </Grid>
                <Grid
                  item
                  sx={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ textAlign: "center" }}
                >
                  Status
                  <h3
                    style={{
                      color:
                        invoiceDetails.Status === "Not Paid" ? "red" : "green",
                    }}
                  >
                    {invoiceDetails.Status}
                  </h3>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={12} sm={12} md={12} lg={12}>
                  <h3 style={{ alignItems: "flex-start" }}>
                    {invoiceDetails.Company}
                  </h3>
                  <p>
                    Invoice #: {invoiceDetails.InvoiceNumber}
                    <br />
                    Invoice Type: {invoiceDetails.InvoiceType}
                    <br />
                  </p>
                  <Grid
                    container
                    spacing={2}
                    style={{ backgroundColor: "#cccccc" }}
                  >
                    <Grid item lg={4} sm={4} md={4}>
                      Total Shipments
                    </Grid>
                    <Grid item lg={4} sm={4} md={4}>
                      Total CO2
                    </Grid>
                    <Grid item lg={4} sm={4} md={4}>
                      Total Amount
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={4} sm={4} md={4}>
                      {invoiceDetails.TotalShipments}
                    </Grid>
                    <Grid item lg={4} sm={4} md={4}>
                      {invoiceDetails.TotalCO2}
                    </Grid>
                    <Grid item lg={4} sm={4} md={4}>
                      {invoiceDetails.TotalAmount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      style={{
                        paddingTop: 150,
                        borderBottom: "2px solid black",
                      }}
                    >
                      {" "}
                      <p style={{ fontSize: 12 }}>
                        Please remit payment transfers to the account below:
                        <br />
                        Fashion Accessories Shippers Association, Inc dba Gemini
                        Shippers Group <br />
                        Bank name: JP Morgan Chase <br />
                        Account Number: 23067272365 <br />
                        Routing/ABA Number: 021000021 <br />
                        Currency: USD <br />
                        Reference: 202102 <br />
                      </p>
                    </Grid>
                    <Grid item lg={12} sm={12} md={12}>
                      {" "}
                      <p style={{ fontSize: 11, textAlign: "center" }}>
                        Fashion Accessories Shippers Association, Inc. dba
                        Gemini Shippers Association
                        <br />
                        Tel 212.947.3424 Fax 212.629.0361 137 West 25th St., 3rd
                        Floor, New York, NY 10001 www.geminishippers.com
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div>
              <SpeedDial
                ariaLabel="SpeedDial example"
                className={classes.speedDial}
                hidden={hidden}
                icon={<SpeedDialIcon />}
                onClose={handleCloseSpDl}
                onOpen={handleOpenSpDl}
                open={openSpDl}
                direction={direction}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={handleCloseSpDl}
                  />
                ))}
              </SpeedDial>
            </div>
          </>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="file-upload-modal-title"
        aria-describedby="file-upload-modal-description"
        className={classes.modal}
        open={openFileUp}
        onClose={handleCloseFileUp}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFileUp}>
          <div
            className={classes.paper}
            style={{ height: "250px", width: "360px" }}
          >
            <input type="file" name="file" onChange={fileChangeHandler} />
            <br />
            <br />
            <Button
              onClick={confirmFileSubmit}
              className={classes.modalBtn}
              variant="contained"
              color="primary"
            >
              <Typography component="span" className="font-semibold flex ">
                Upload File to server
              </Typography>
            </Button>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
